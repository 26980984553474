import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showError, loginWithSiteAppId } from "../../redux/common/actions";
import ConfirmDialog from 'components/Elements/ConfirmDialog';

export const withAnalytics = (WrappedComponent) => {
    return function WithAnalyticsComponent(props) {
        const dispatch = useDispatch();
        const [showSwitchDialog, setShowSwitchDialog] = useState(false);
        const [targetSiteAppId, setTargetSiteAppId] = useState(null);

        // Get user data from storage
        const userData = JSON.parse(localStorage.getItem("me")) ||
            JSON.parse(sessionStorage.getItem("me"));
        
        const currentSiteAppId = userData?.siteAppID?.toString();

        useEffect(() => {
            const checkAndShowDialog = () => {
                const urlParams = new URLSearchParams(window.location.search);
                const urlSiteAppId = urlParams.get("siteAppId");
                
                if (!urlSiteAppId) {
                    // If no siteAppId in URL, add the current one
                    const newUrl = new URL(window.location.href);
                    newUrl.searchParams.set("siteAppId", currentSiteAppId);
                    window.history.pushState({}, '', newUrl);
                    return;
                }
                
                // Check if we need to show the switch dialog
                if (currentSiteAppId !== urlSiteAppId) {
                    setTargetSiteAppId(urlSiteAppId);
                    setShowSwitchDialog(true);
                }
            };

            checkAndShowDialog();
        }, [currentSiteAppId]);

        const switchSiteApp = async () => {
            try {
                await dispatch(loginWithSiteAppId(targetSiteAppId));
            } catch (error) {
                dispatch(showError("Access denied. You do not have permission to access this application."));
            }
        };

        const handleConfirmSwitch = () => {
            if (targetSiteAppId) {
                switchSiteApp();
            }
            setShowSwitchDialog(false);
        };

        const handleCancelSwitch = () => {
            setShowSwitchDialog(false);
            setTargetSiteAppId(null);
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.set("siteAppId", currentSiteAppId);
            window.history.pushState({}, '', newUrl);
            window.location.reload();
        };

        return (
            <>
                <ConfirmDialog
                    open={showSwitchDialog}
                    title="Switch Site Application"
                    message="You are about to switch to a different Site Application. Would you like to continue?"
                    confirmButtonText="Switch"
                    onConfirm={handleConfirmSwitch}
                    onCancel={handleCancelSwitch}
                />
                <WrappedComponent {...props} />
            </>
        );
    };
}; 