import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { getDefectRiskRatings } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../redux/common/actions';
import { useFilters } from './FilterContext';

export const RiskRatingFilter = () => {
  const dispatch = useDispatch();
  const [riskRatings, setRiskRatings] = useState([]);
  const { filterValues, updateFilter } = useFilters();

  useEffect(() => {
    updateFilter('riskRatings', { id: '', name: 'Show All' });
  }, []);

  // Load risk ratings
  useEffect(() => {
    const loadRiskRatings = async () => {
      try {
        const ratings = await getDefectRiskRatings();
        if (ratings?.status) {
          setRiskRatings([{id: '', name: 'Show All'}, ...ratings.data]);
        }
      } catch (error) {
        dispatch(showError('Failed to load risk ratings'));
      }
    };
    loadRiskRatings();
  }, [dispatch]);

  if (!filterValues.riskRatings) {
    return null;
  } 

  return (
    <Grid item lg={3} md={6} xs={12}>
      <DyanamicDropdown
        dataSource={riskRatings}
        columns={[{ name: 'name', id: 1 }]}
        showHeader={false}
        width="100%"
        placeholder="Select Risk Rating"
        onChange={item => updateFilter('riskRatings', item, { defectRiskRatingId: item.id })}
        selectdValueToshow="name"
        selectedValue={filterValues.riskRatings?.value}
        label="Filter by Risk Rating"
        icon={<FilterListIcon style={{ color: 'rgb(48,122,215)' }} />}
        required={false}
        showBorderColor
      />
    </Grid>
  );
}; 
