import {
	endOfDay,
	endOfMonth,
	endOfWeek,
	startOfDay,
	startOfMonth,
	startOfWeek,
	subDays,
	subMonths,
	subWeeks,
	subYears,
	startOfYear,
	endOfYear,
} from "date-fns";
import { convertDateToUTC } from "helpers/utils";

export const filterByDateOptions = (
	todayDate,
	customCaptions,
	weekStartDay = 1
) => {
	// Convert our week day (0-6, Monday-Sunday) to date-fns week day (0-6, Sunday-Saturday)
	const dateFnsWeekStartDay = (weekStartDay + 1) % 7;

	console.log('Debug dates:', {
		todayDate: todayDate.toISOString(),
		weekStartDay,
		dateFnsWeekStartDay,
		lastWeekStart: startOfWeek(subWeeks(todayDate, 1), { weekStartsOn: dateFnsWeekStartDay }).toISOString(),
		lastWeekEnd: endOfWeek(subWeeks(todayDate, 1), { weekStartsOn: dateFnsWeekStartDay }).toISOString(),
		lastWeekStartUTC: convertDateToUTC(startOfWeek(subWeeks(todayDate, 1), { weekStartsOn: dateFnsWeekStartDay })),
		lastWeekEndUTC: convertDateToUTC(endOfWeek(subWeeks(todayDate, 1), { weekStartsOn: dateFnsWeekStartDay }))
	});

	return [
		{ id: "", name: "Show All", fromDate: "", toDate: "" },
		{
			id: 1,
			name: "Today",
			fromDate: convertDateToUTC(startOfDay(todayDate)),
			toDate: convertDateToUTC(endOfDay(todayDate)),
		},
		{
			id: 9,
			name: "Last Week",
			fromDate: convertDateToUTC(startOfWeek(subWeeks(todayDate, 1), { weekStartsOn: dateFnsWeekStartDay })),
			toDate: convertDateToUTC(endOfWeek(subWeeks(todayDate, 1), { weekStartsOn: dateFnsWeekStartDay })),
		},
		{
			id: 3,
			name: "Last Month",
			fromDate: convertDateToUTC(startOfMonth(subMonths(todayDate, 1))),
			toDate: convertDateToUTC(endOfMonth(subMonths(todayDate, 1))),
		},
		{
			id: 11,
			name: "Last Year",
			fromDate: convertDateToUTC(startOfYear(subYears(todayDate, 1))),
			toDate: convertDateToUTC(endOfYear(subYears(todayDate, 1))),
		},
		{
			id: 7,
			name: "Last 7 Days",
			fromDate: convertDateToUTC(subDays(todayDate, 7)),
			toDate: convertDateToUTC(endOfDay(todayDate)),
		},
		{
			id: 8,
			name: "Last 14 Days",
			fromDate: convertDateToUTC(subDays(todayDate, 14)),
			toDate: convertDateToUTC(endOfDay(todayDate)),
		},
		{
			id: 6,
			name: "Last 30 Days",
			fromDate: convertDateToUTC(subDays(todayDate, 30)),
			toDate: convertDateToUTC(endOfDay(todayDate)),
		},
		{
			id: 10,
			name: "Last 90 Days",
			fromDate: convertDateToUTC(subDays(todayDate, 90)),
			toDate: convertDateToUTC(endOfDay(todayDate)),
		},
		{ id: 5, name: "Custom Range..." },
	];
};

export const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
