import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart as RechartsLineChart,
} from "recharts";
import React, { useState, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import { Legend } from '../Layout';
import { useReport } from '../ReportContext';
import { useTimeGrouping, useSeries, useChartFormatting, useTransformData } from '../hooks';

const useStyles = makeStyles()((theme) => ({
  customTooltip: {
    background: "#e9e9e9",
    padding: "6px 12px",
    border: " 1px solid",
    borderRadius: "3px",
  },
  toolTipLabel: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "& p": {
      margin: "0px !important",
    },
  },
  colorBox: {
    width: "14px",
    height: "14px",
  },
  label: {
    margin: 0,
  },
  clickablePoint: {
    cursor: 'pointer',
  }
}));

export const LineChart = ({ data: chartData, config }) => {
  const { classes } = useStyles();
  const [showToolTip] = useState(false);
  const [tooltipPosition, settooltipPosition] = useState({
    active: false,
  });
  const { handleDrilldown } = useReport();
  
  const { timeGrouping } = useTimeGrouping(chartData);

  const { series } = useSeries(config);
  
  // Use the chart formatting hook with the determined timeGrouping
  const { formatXAxisTick, CustomTooltip } = useChartFormatting(timeGrouping);

  const transformedData = useTransformData(chartData);
  
  const handlePointClick = useCallback((e, dataPoint, item) => {
    // Only process drilldown if the config has drilldown feature and series allows drilldown
    if (config?.drilldown && item.drilldown !== false) {
      const drilldownData = {
        ...dataPoint,
        seriesKey: item.key,
        date: dataPoint.label || dataPoint.yearMonth,
        value: dataPoint.count
      };
      
      // Call the handleDrilldown function from ReportContext
      handleDrilldown(drilldownData);
    }
  }, [config, handleDrilldown]);

  return (
    <>
      <div style={{ 
        height: "65vh", 
        width: "100%", 
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center" 
      }}>
        <div style={{ height: "100%", flexGrow: 1 }}>
          <ResponsiveContainer width="100%" height="100%">
            <RechartsLineChart
              width={500}
              height={300}
              margin={{
                top: 10,
                right: 30,
                left: 40,
                bottom: 50,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="label"
                xAxisId="xAxis"
                padding={{ top: 80, bottom: 80 }}
                margin={{ top: 10 }}
                dy={16}
                angle={-45}
                textAnchor="end"
                height={60}
                tickFormatter={formatXAxisTick}
                interval="preserveStartEnd"
                allowDuplicatedCategory={false}
                scale="point"
              />
              <YAxis
                orientation="left"
                label={{ value: "Count", angle: -90, dx: -30 }}
                dx={-5}
                type="number"
                domain={[0, (dataMax) => Math.round(dataMax) + 2]}
              />
              {tooltipPosition?.active ? (
                <Tooltip
                  content={<CustomTooltip showToolTip={tooltipPosition} legendItems={series} />}
                  formatter={(value) => [value, "average"]}
                  wrapperStyle={{
                    opacity: tooltipPosition?.active ? 1 : 0,
                  }}
                />
              ) : (
                <Tooltip
                  content={<CustomTooltip showToolTip={showToolTip} legendItems={series} />}
                  position={tooltipPosition}
                  cursor={{ fill: "transparent" }}
                />
              )}
              {series.map((item, seriesIndex) => (
                <Line
                  key={item.key}
                  xAxisId="xAxis"
                  type="linear"
                  dot={{ 
                    strokeWidth: 2, 
                    r: 8, 
                    fill: item.getColorByIndex(seriesIndex),
                    className: config?.drilldown ? classes.clickablePoint : ''
                  }}
                  data={transformedData}
                  dataKey={item.key}
                  stroke={item.getColorByIndex(seriesIndex)}
                  isAnimationActive={false}
                  activeDot={{
                    onMouseOver: (e, i) => {
                      settooltipPosition((prev) => ({
                        active: true,
                      }));
                    },
                    onMouseLeave: (e) => {
                      settooltipPosition((prev) => ({
                        active: false,
                      }));
                    },
                    onClick: (e, i) => {
                      if (config?.drilldown) {
                        handlePointClick(e, i.payload, item);
                      }
                    },
                    strokeWidth: 40,
                    stroke: "transparent",
                    cursor: config?.drilldown ? "pointer" : "default",
                    r: 8,
                  }}
                />
              ))}
            </RechartsLineChart>
          </ResponsiveContainer>
        </div>
        <Legend items={series} chartType="line" position="right" />
      </div>
    </>
  );
}; 