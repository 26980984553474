import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { FilterContainer } from './Filters/FilterContainer';
import { ChartContainer } from './Layout';
import { ReportProvider, useReport } from './ReportContext';
import { useChartData } from './hooks';

// Loading component for the chart
const ChartLoadingComponent = () => (
  <Box 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      minHeight: '300px',
      width: '100%'
    }}
  >
    <CircularProgress color="primary" />
  </Box>
);

const ReportContent = () => {
  const { config, isDrilledDown } = useReport();
  const { isLoading, chartData, subtitle, invalidFiltersMessage } = useChartData();

  return (
    <div className="container">
      <FilterContainer />
      <div className="mt-8">
        <ChartContainer
          title={config.title}
          subtitle={subtitle}
          data={chartData}
          isLoading={isLoading}
          isDrilledDown={isDrilledDown}
          invalidFiltersMessage={invalidFiltersMessage}
          LoadingComponent={<ChartLoadingComponent />}
        />
      </div>
    </div>
  );
};

export const ReportChart = ({ config }) => {
  return (
    <ReportProvider config={config}>
      <ReportContent />
    </ReportProvider>
  );
};
