import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import { ReportChart } from '.';
import NavDetails from "components/Elements/NavDetails";
import { appPath, newAnalyticsPath } from "helpers/routePaths";
import TabTitle from "components/Elements/TabTitle";

const useStyles = makeStyles()((theme) => ({
  header: {
    marginTop: '35px'
  },
  title: {
    marginBottom: theme.spacing(2),
  }
}));

const ReportPage = ({ title, chartConfig, customCrumbs = [] }) => {
  const { classes } = useStyles();

  // Default crumbs with Analytics as the first item
  const defaultCrumbs = [
    { id: 1, name: "Analytics (Experimental)", url: appPath + newAnalyticsPath },
  ];

  // If customCrumbs is provided, add it to the default crumbs
  // Otherwise, add the title as the second crumb
  const staticCrumbs = customCrumbs.length > 0 
    ? [...defaultCrumbs, ...customCrumbs] 
    : [...defaultCrumbs, { id: 2, name: title }];

  return (
    <>
      <TabTitle title={title} />
      <div className={classes.container}>
        <NavDetails
          history={false}
          status={false}
          staticCrumbs={staticCrumbs}
        />
        
        <div className={classes.header}>
          <ReportChart config={chartConfig} />
        </div>
      </div>
    </>
  );
};

export default ReportPage; 