import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { getDefectTypes } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../redux/common/actions';
import { useFilters } from './FilterContext';

export const DefectTypeFilter = () => {
  const dispatch = useDispatch();
  const [defectTypes, setDefectTypes] = useState([]);
  const { filterValues, updateFilter } = useFilters();

  useEffect(() => {
    updateFilter('defectType', { id: '', name: 'Show All' });
  }, []);

  // Load defect types
  useEffect(() => {
    const loadDefectTypes = async () => {
      try {
        const types = await getDefectTypes();
        if (types?.status) {
          setDefectTypes([{id: '', name: 'Show All'}, ...types.data]);
        }
      } catch (error) {
        dispatch(showError('Failed to load defect types'));
      }
    };
    loadDefectTypes();
  }, [dispatch]);

  if (!filterValues.defectType) {
    return null;
  } 

  return (
    <Grid item lg={3} md={6} xs={12}>
      <DyanamicDropdown
        dataSource={defectTypes}
        columns={[{ name: 'name', id: 1 }]}
        showHeader={false}
        width="100%"
        placeholder="Select Defect Type"
        onChange={item => updateFilter('defectType', item, { defectTypeId: item.id })}
        selectdValueToshow="name"
        selectedValue={filterValues.defectType?.value}
        label="Filter by Defect Type"
        icon={<FilterListIcon style={{ color: 'rgb(48,122,215)' }} />}
        required={false}
        showBorderColor
      />
    </Grid>
  );
}; 