export function changeDate(d) {
	const date = new Date(d);
	let dd = date.getDate();
	let mm = date.getMonth() + 1;
	const yyyy = date.getFullYear();
	if (dd < 10) {
		dd = `0${dd}`;
	}
	if (mm < 10) {
		mm = `0${mm}`;
	}
	return `${dd}/${mm}/${yyyy}`;
}
export function changeDateForSapError(d) {
	const date = new Date(d);
	let dd = date.getDate();
	let mm = date.getMonth() + 1;
	const yy = String(date.getFullYear()).slice(-2);
	const yyyy = date.getFullYear();
	const HH = String(date.getHours()).padStart(2, "0");
	const MM = String(date.getMinutes()).padStart(2, "0");

	if (dd < 10) {
		dd = `0${dd}`;
	}
	if (mm < 10) {
		mm = `0${mm}`;
	}

	return `${dd}/${yy}/${yyyy} at ${HH}:${MM}`;
}

export function headerDateFormat(d) {
	const date = new Date(d);
	let dd = date.getDate();
	let mm = date.getMonth() + 1;
	const hh = date.getHours();
	const min = date.getMinutes();
	const yyyy = date.getFullYear();
	if (dd < 10) {
		dd = `0${dd}`;
	}
	if (mm < 10) {
		mm = `0${mm}`;
	}
	return `${dd}.${mm}.${yyyy} / ${hh}:${min} AEDT`;
}
export function headerDateFormatLastLogin(d) {
	if (!d) return null;
	
	try {
		// Parse the UTC date string and create a UTC Date object
		const utcDate = new Date(d + 'Z'); // Adding 'Z' to ensure UTC interpretation
		if (isNaN(utcDate.getTime())) return null;

		// Use browser's locale for date and time formatting
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		};

		// Convert UTC to local time and ensure AM/PM is uppercase
		return utcDate.toLocaleString(navigator.language, options)
			.replace(/am|pm/gi, match => match.toUpperCase());
			
	} catch (error) {
		console.error('Date formatting error:', error);
		return null;
	}
}

export function materialUiDate(d) {
	const date = new Date(d);
	let dd = date.getDate();
	let mm = date.getMonth() + 1;
	const yyyy = date.getFullYear();
	if (dd < 10) {
		dd = `0${dd}`;
	}
	if (mm < 10) {
		mm = `0${mm}`;
	}
	return `${yyyy}-${mm}-${dd}`;
}

export function startOfDay() {
	return new Date(new Date().setHours(0, 0, 0, 0));
}

export function endOfDay() {
	return new Date(new Date().setHours(23, 59, 59, 999));
}

export const convertToAmPm = (time) => {
	if (!time) return "";
	const [hours, minutes] = time.split(":");
	const h = parseInt(hours);
	const ampm = h >= 12 ? "PM" : "AM";
	const hour = h % 12 || 12;
	return `${hour}:${minutes} ${ampm}`;
};
