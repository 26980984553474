import React, { useState, useCallback } from 'react';
import { 
  Treemap as RechartsTreemap, 
  ResponsiveContainer, 
  Tooltip 
} from 'recharts';
import { makeStyles } from 'tss-react/mui';
import { useReport } from '../ReportContext';
import { Legend } from '../Layout';
import { useSeries, useTransformData } from '../hooks';

const useStyles = makeStyles()((theme) => ({
  treeMapStyles: {
    height: '65vh',
    width: '100%',
  },
  clickableRect: {
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
  },
  customTooltip: {
    background: "#e9e9e9",
    padding: "6px 12px",
    border: "1px solid",
    borderRadius: "3px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.15)",
  },
  toolTipLabel: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "& p": {
      margin: "0px !important",
    },
  },
  colorBox: {
    width: "14px",
    height: "14px",
  },
}));

const CustomTooltip = ({ active, payload }) => {
  const { classes } = useStyles();
  
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const percentage = data.percentage || 
      (data.total ? Math.round((data.count / data.total) * 100) : null);
    
    return (
      <div className={classes.customTooltip}>
        <div style={{ 
          borderBottom: '1px solid #ccc', 
          paddingBottom: '6px', 
          marginBottom: '6px',
          fontWeight: 'bold' 
        }}>
          {data.name}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div 
            style={{ 
              width: '12px', 
              height: '12px', 
              backgroundColor: data.fill,
              borderRadius: '2px'
            }} 
          />
          <div>
            <div>Count: <strong>{data.count}</strong></div>
            {percentage && <div>Percentage: <strong>{percentage}%</strong></div>}
          </div>
        </div>
      </div>
    );
  }
  
  return null;
};

const CustomTreemapContent = ({ 
  x, 
  y, 
  width, 
  height, 
  name, 
  count,
  percentage,
  index, 
  fill, 
  onClick,
  isClickable
}) => {
  const [hovered, setHovered] = useState(false);
  const { classes } = useStyles();
  
  // Add spacing between rectangles (reduce size by 4px on each side)
  const padding = 4;
  const adjustedX = x + padding;
  const adjustedY = y + padding;
  const adjustedWidth = width - (padding * 2);
  const adjustedHeight = height - (padding * 2);
  
  // Calculate font size based on rectangle size, but make it larger
  const fontSize = Math.min(adjustedWidth / 8, adjustedHeight / 4, 24);
  
  // Only show text if rectangle is big enough
  const showText = adjustedWidth > 40 && adjustedHeight > 40;
  // Show percentage only for larger rectangles
  const showPercentage = adjustedWidth > 80 && adjustedHeight > 80;
  
  // Enhanced hover effect
  const hoverScale = 0.97; // Scale down slightly on hover
  const hoverX = hovered ? adjustedX + (adjustedWidth * (1 - hoverScale)) / 2 : adjustedX;
  const hoverY = hovered ? adjustedY + (adjustedHeight * (1 - hoverScale)) / 2 : adjustedY;
  const hoverWidth = hovered ? adjustedWidth * hoverScale : adjustedWidth;
  const hoverHeight = hovered ? adjustedHeight * hoverScale : adjustedHeight;
  
  return (
    <g>
      <rect
        x={hoverX}
        y={hoverY}
        width={hoverWidth}
        height={hoverHeight}
        rx={3}
        ry={3}
        fill={hovered ? `${fill}ee` : fill}
        onClick={() => onClick({
          name,
          count,
          percentage,
          fill
        })}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={isClickable ? classes.clickableRect : ''}
        stroke={hovered ? "#ffffff" : "#fff"}
        strokeWidth={hovered ? 2 : 1}
        style={{ transition: 'all 0.2s ease-in-out' }}
      />
      {showText && (
        <>
          <text
            x={hoverX + hoverWidth / 2}
            y={hoverY + hoverHeight / 2 - (showPercentage ? fontSize : fontSize / 2)}
            textAnchor="middle"
            fill="#fff"
            fontSize={fontSize}
            fontWeight="bold"
            style={{ 
              pointerEvents: 'none',
              textShadow: '0px 0px 3px rgba(0,0,0,0.7)'
            }}
          >
            {name}
          </text>
          <text
            x={hoverX + hoverWidth / 2}
            y={hoverY + hoverHeight / 2 + (showPercentage ? 0 : fontSize)}
            textAnchor="middle"
            fill="#fff"
            fontSize={fontSize}
            style={{ 
              pointerEvents: 'none',
              textShadow: '0px 0px 3px rgba(0,0,0,0.7)'
            }}
          >
            {count} {showPercentage && `(${percentage}%)`}
          </text>
        </>
      )}
    </g>
  );
};

export const TreeMap = ({ data, config }) => {
  const { classes } = useStyles();
  const { handleDrilldown } = useReport();
  
  // Check if data is in the expected format
  const chartData = useTransformData(data);
  
  const { series, showLegend } = useSeries(config);

  // Currently only supporting single series
  const treemapSeries = series[0];

  // Calculate total for percentages
  const total = chartData.reduce((sum, item) => sum + (item.count || 0), 0);

  // Transform data for treemap format
  const transformedData = chartData.map((item, index) => ({
    ...item,
    value: item.count,
    fill: treemapSeries.getColorByIndex(index),
    total,
    percentage: Math.round((item.count / total) * 100)
  }));

  // Handle click on treemap rectangle for drilldown
  const handleRectClick = useCallback((entry) => {
    // Only process drilldown if the config has drilldown feature and series allows drilldown
    if (config?.drilldown && treemapSeries.drilldown !== false) {
      const drilldownData = {
        ...entry,
        seriesKey: treemapSeries.key,
        value: entry.count,
        // Include name as label for consistency
        label: entry.name
      };
      
      // Call the handleDrilldown function from ReportContext
      handleDrilldown(drilldownData);
    }
  }, [config, handleDrilldown, treemapSeries]);

  return (
    <>
      <div className={classes.treeMapStyles} style={{ 
        backgroundColor: '#ffffff',
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center" 
      }}>
        <div style={{ height: "100%", flexGrow: 1 }}>
          <ResponsiveContainer width="100%" height="100%" style={{ backgroundColor: '#ffffff' }}>
            <RechartsTreemap
              data={transformedData}
              dataKey="value"
              nameKey="name"
              aspectRatio={4/3}
              isAnimationActive={false}
              fill="#ffffff"
              content={
                <CustomTreemapContent 
                  onClick={(data) => {
                    if (config?.drilldown) {
                      handleRectClick(data);
                    }
                  }}
                  isClickable={!!config?.drilldown}
                />
              }
            >
              <Tooltip content={<CustomTooltip />} />
            </RechartsTreemap>
          </ResponsiveContainer>
        </div>
        {showLegend() && <Legend items={series} chartType="treemap" position="right" />}
      </div>
    </>
  );
}; 