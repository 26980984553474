import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { getSiteDepartments } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../redux/common/actions';
import { useFilters } from './FilterContext';

export const DepartmentFilter = () => {
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);
  const { filterValues, updateFilter } = useFilters();

  const {
    siteAppID,
    site: { siteDepartmentID, siteDepartmentName },
  } = JSON.parse(localStorage.getItem("me")) ||
      JSON.parse(sessionStorage.getItem("me"));

  // Initialize department filter with values from localStorage/sessionStorage
  useEffect(() => {
    if (!filterValues.department) {
      updateFilter(
        'department', 
        { 
          id: siteDepartmentID ?? '', 
          name: siteDepartmentName ?? 'Show All' 
        }, 
        { siteDepartmentId: siteDepartmentID ?? '' }
      );
    }
  }, []);

  // Load departments data
  useEffect(() => {
    const loadDepartments = async () => {
      try {
        const response = await getSiteDepartments(siteAppID);
        if (response?.status) {
          setDepartments([
            {id: '', name: 'Show All'}, 
            ...response.data
          ]);
        }
      } catch (error) {
        dispatch(showError('Failed to load departments'));
      }
    };
    loadDepartments();
  }, [dispatch, siteAppID]);

  return (
    <Grid item lg={3} md={6} xs={12}>
      <DyanamicDropdown
        dataSource={departments}
        columns={[{ name: 'name', id: 1 }]}
        showHeader={false}
        width="100%"
        placeholder="Select Department"
        onChange={item => updateFilter('department', item, { siteDepartmentId: item.id })}
        selectdValueToshow="name"
        selectedValue={filterValues.department?.value}
        label="Filter by Department"
        icon={<FilterListIcon style={{ color: 'rgb(48,122,215)' }} />}
        required={false}
        showBorderColor
      />
    </Grid>
  );
}; 
