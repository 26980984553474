import React from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { Apis } from "services/api";
import { getConditionMonitoring } from "services/analytics";

const ConditionMonitoringReport = () => {
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));

  const chartConfig = {
    id: 'condition-monitoring',
    title: 'Condition Monitoring',
    fetchData: async (params) => {
      // Return invalid filters status if modelVersionTaskQuestionID is not set
      if (!params.modelVersionTaskQuestionId) {
        return { 
          status: false, 
          invalidFilters: true,
          message: `Please select ${customCaptions?.question || 'Question'}.`
        };
      }
      return await getConditionMonitoring(params);
    },
    chartType: 'line',
    series: [
      { key: 'valueNumeric', label: 'Response', color: '#3b679b' }
    ],
    transformData: (response) => {
      return response?.data?.data;

      // Transform data to include defects and maintain original structure
      // const transformedData = response.data.data.map(item => ({
      //   ...item,
      //   date: new Date(item.date + "Z").getTime(),
      //   valueNumeric: item.valueNumeric,
      //   defects: item.defects || [],
      //   siteAssetName: item.siteAssetName || '',
      // }));

      // console.log('Transformed Data:', transformedData);
      // return transformedData;
    },
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: true,
      asset: { 
        show: model => model?.modelTemplateType === 'A' 
      },
      role: { 
        show: true
      },
      interval: true,
      question: { 
        required: true,
        show: true
      }
    }
  };

  return (
    <ReportPage 
      title="Condition Monitoring"
      chartConfig={chartConfig}
    />
  );
};

export default ConditionMonitoringReport; 
