import React from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { getDefectsRegistered } from "services/analytics";
import { Apis } from "services/api";
import { defectStatusTypes } from "helpers/constants";
import useChartColors from '../ReportChart/hooks/useChartColors';

const DefectsRegisteredReport = () => {
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  const { getAllColors } = useChartColors();
  const chartConfig = {
    id: 'defects-registered',
    title: `${customCaptions?.defectPlural} Registered`,
    fetchData: async (params) => {
      return await getDefectsRegistered(params);
    },
    allowedChartTypes: ['horizontal-bar', 'pie'],
    defaultChartType: 'horizontal-bar',
    series: [
        { label: 'Registered', key: 'count', colors: getAllColors() }
    ],
    transformData: (response) => {
      return response?.data?.data;
    },
    viewData: '/app/defects', //This could be a function that returns the path based on the data
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: true,
      riskRatings: true,
      defectType: true,
      defectStatusType: true,
      workOrder: true,
    }
  };

  return (
    <ReportPage 
      title={`${customCaptions?.defectPlural} Registered`}
      chartConfig={chartConfig}
    />
  );
};

export default DefectsRegisteredReport; 
