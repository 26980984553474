import React from 'react';
import { ButtonGroup, Button } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';

const CHART_TYPE_ICONS = {
  'vertical-bar': <BarChartIcon />,
  'horizontal-bar': <BarChartIcon style={{ transform: 'rotate(90deg)' }} />,
  'pie': <PieChartIcon />,
  'line': <ShowChartIcon />,
  'bar': <BarChartIcon />,
  'stacked-bar': <StackedBarChartIcon />,
  'horizontal-stacked-bar': <StackedBarChartIcon style={{ transform: 'rotate(90deg)' }} />,
  'tree-map': <ViewComfyIcon />,
  'multi-bar-line': <MultilineChartIcon />
};

export const ChartTypeSelector = ({ allowedTypes = [], currentType, onTypeChange }) => {
  return (
    <ButtonGroup size="small" aria-label="chart type selector">
      {allowedTypes && allowedTypes.map(type => (
        <Button
          key={type}
          onClick={() => onTypeChange(type)}
          variant={currentType === type ? 'contained' : 'outlined'}
          sx={{
            minWidth: '40px',
            backgroundColor: currentType === type ? 'rgb(48,122,215)' : 'transparent',
            '&:hover': {
              backgroundColor: currentType === type ? 'rgb(48,122,215)' : 'rgba(48,122,215,0.1)'
            }
          }}
        >
          {CHART_TYPE_ICONS[type]}
        </Button>
      ))}
    </ButtonGroup>
  );
}; 
