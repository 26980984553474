import React from 'react';
import { LineChart } from './LineChart';
import { 
  VerticalBarChart, 
  HorizontalBarChart, 
  StackedBarChart, 
  HorizontalStackedBarChart 
} from './BarChart';
import { PieChart } from './PieChart';
import { TreeMap } from './TreeMap';
import { MultiBarLineChart } from './MultiChart';

// Define chart components
const CHART_TYPES = {
  'line': LineChart,
  'vertical-bar': VerticalBarChart,
  'horizontal-bar': HorizontalBarChart,
  'stacked-bar': StackedBarChart,
  'horizontal-stacked-bar': HorizontalStackedBarChart,
  'pie': PieChart,
  'tree-map': TreeMap,
  'multi-bar-line': MultiBarLineChart
};

export const ChartFactory = ({ type, data, config, isDrilledDown }) => {
  // Special handling for condition monitoring
  if (config.id === 'condition-monitoring') {
    if (!Array.isArray(data)) {
      return null;
    }
    return <LineChart data={data} config={config} isDrilledDown={isDrilledDown} />;
  }

  // Check if data exists
  if (!data) {
    return null;
  }

  // Handle different data formats
  const isValidData = Array.isArray(data) 
    ? data.length > 0 
    : Object.values(data).every(d => Array.isArray(d) && d.length > 0);

  if (!isValidData) {
    return null;
  }

  const ChartComponent = CHART_TYPES[type];
  
  if (!ChartComponent) {
    console.warn(`Unknown chart type: ${type}`);
    return null;
  }

  return <ChartComponent 
    data={data} 
    config={config}
    isDrilledDown={isDrilledDown}
  />;
}; 