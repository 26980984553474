import React from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { Apis } from "services/api";
import { getSkippedTasksByReason } from "services/analytics";
import useChartColors from '../ReportChart/hooks/useChartColors';
const ServiceSkippedReport = () => {
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  const { getAllColors } = useChartColors();

  const chartConfig = {
    id: 'service-skipped',
    title: `${customCaptions?.service} Skipped ${customCaptions?.task} Reasons`,
    fetchData: async (params) => {
      return await getSkippedTasksByReason(params);
    },
    allowedChartTypes: ['horizontal-bar', 'pie'],
    defaultChartType: 'horizontal-bar',
    series: [
      { label: 'Count', key: 'count', colors: getAllColors() }
    ],
    transformData: (response) => {
      return response?.data?.data;
    },
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: true
    },
    drilldown: {
      series: [
        { 
          label: `${customCaptions?.model || 'Model'} Count`, 
          key: 'count', 
          showLegend: false
        }
      ],
      currentDrilldownLabel: `${customCaptions?.model || 'Model'}`,
      getParams: (dataPoint) => {
        return {
          skipReasonId: dataPoint.id
        };
      },
      drilldown: {
        series: [
          { 
            label: `${customCaptions?.asset || 'Asset'} Count`, 
            key: 'count', 
            legend: false
          }
        ],
        currentDrilldownLabel: `${customCaptions?.asset || 'Asset'}`,
        getParams: (dataPoint) => {
          return {
            modelId: dataPoint.id
          };
        }
      }
    }
  };

  return (
    <ReportPage 
      title={`${customCaptions?.service} Skipped ${customCaptions?.task} Reasons`}
      chartConfig={chartConfig}
    />
  );
};

export default ServiceSkippedReport; 
