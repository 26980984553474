import React, { createContext, useContext } from 'react';
import { FilterProvider } from './Filters/FilterContext';
import { useDrilldown } from './hooks/useDrilldown';

export const ReportContext = createContext();

export const useReport = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error('useReport must be used within a ReportProvider');
  }
  return context;
};

export const ReportProvider = ({ config, children }) => {
  // Use the custom hook for all drilldown functionality
  const drilldownState = useDrilldown(config);
  
  const filterConfig = {
    filters: config.filters,
  };

  return (
    <ReportContext.Provider value={{
      ...drilldownState
    }}>
      <FilterProvider config={filterConfig}>
        {children}
      </FilterProvider>
    </ReportContext.Provider>
  );
}; 