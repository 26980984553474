import React, { useState, useCallback } from 'react';
import { PieChart as RechartsPieChart, Pie, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { makeStyles } from 'tss-react/mui';
import useWindowSize from 'hooks/useDynamicScreenSize';
import { useReport } from '../ReportContext';
import { Legend } from '../Layout';
import { useSeries, useTransformData } from '../hooks';

const RADIAN = Math.PI / 180;

const useStyles = makeStyles()((theme) => ({
  pieStyles: {
    height: '65vh',
  },
  clickableSlice: {
    cursor: 'pointer',
  }
}));

export const PieChart = ({ data, config }) => {
  const { classes } = useStyles();
  const windowSize = useWindowSize();
  const [mouseOver, setMouseOver] = useState(null);
  const [mouseOverInner, setMouseOverInner] = useState(null);
  const { handleDrilldown } = useReport();
  
  // Check if data is in the expected format
  const chartData = useTransformData(data);
  
  const { series, showLegend } = useSeries(config);

  // Determine if we have one or two series
  const hasTwoSeries = series.length > 1;
  
  // Get the series for outer and inner rings (if two series)
  const outerSeries = series[0]; // First series
  const innerSeries = hasTwoSeries ? series[1] : null; // Second series if exists

  // Check if drilldown is enabled for each series
  const outerSeriesDrilldownEnabled = outerSeries?.drilldown !== false;
  const innerSeriesDrilldownEnabled = innerSeries?.drilldown !== false;

  // Handle click on pie slice for drilldown
  const handleSliceClick = useCallback((entry, index, seriesIndex) => {
    // Check if drilldown is enabled for this series
    const isSeriesDrilldownEnabled = seriesIndex === 0 
      ? outerSeriesDrilldownEnabled 
      : innerSeriesDrilldownEnabled;
    
    // Only process drilldown if the config has drilldown feature and series allows drilldown
    if (config?.drilldown && isSeriesDrilldownEnabled) {
      const drilldownData = {
        ...entry,
        value: entry.count,
        seriesIndex // Add seriesIndex to identify which series was clicked
      };
      
      // Call the handleDrilldown function from ReportContext
      handleDrilldown(drilldownData);
    }
  }, [config, handleDrilldown, outerSeriesDrilldownEnabled, innerSeriesDrilldownEnabled]);

  // Process data for outer ring (first series)
  const outerRingData = React.useMemo(() => {
    if (!chartData) return [];

    if (!hasTwoSeries) {
      // For single series, use the label property for the name
      return chartData.map(item => ({
        name: item.label || item.name,
        count: item.count,
        items: [item],
        ...item
      }));
    }
    
    // Group by the outer series key and get total counts
    const grouped = chartData.reduce((acc, item) => {
      const key = item[outerSeries.key] || 'Unknown';
      if (!acc[key]) {
        acc[key] = {
          name: key,
          count: 0,
          items: [] // Keep the actual items for inner ring reference
        };
      }
      acc[key].count += item.count;
      acc[key].items.push(item);
      return acc;
    }, {});
    
    return Object.values(grouped);
  }, [chartData, outerSeries.key, hasTwoSeries]);

  // Process data for inner ring - show all items by default
  const innerRingData = React.useMemo(() => {
    if (!hasTwoSeries || !chartData || !innerSeries?.key) return [];
    
    // If hovering over outer segment, only show its items
    if (mouseOver !== null) {
      const selectedOuterSegment = outerRingData[mouseOver];
      return selectedOuterSegment?.items || [];
    }
    
    // Group inner items by their outer series key to maintain alignment
    const grouped = {};
    chartData.forEach(item => {
      const outerKey = item[outerSeries.key];
      if (!grouped[outerKey]) {
        grouped[outerKey] = [];
      }
      grouped[outerKey].push(item);
    });

    // Return items in the same order as outer ring segments
    return outerRingData.reduce((acc, outerSegment) => {
      return acc.concat(grouped[outerSegment.name] || []);
    }, []);
  }, [hasTwoSeries, chartData, innerSeries?.key, mouseOver, outerRingData, outerSeries.key]);

  // Handle mouse enter for outer slice
  const handleOuterSliceMouseEnter = (entry, index) => {
    setMouseOver(index);
    // Inner ring updates automatically via memo dependency
  };

  // Handle mouse leave for outer slice
  const handleOuterSliceMouseLeave = () => {
    setMouseOver(null);
  };

  // Handle mouse enter for inner slice
  const handleInnerSliceMouseEnter = (entry, index) => {
    setMouseOverInner([index]);
  };

  // Handle mouse leave for inner slice
  const handleInnerSliceMouseLeave = () => {
    setMouseOverInner(null);
  };

  const renderCustomizedLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, index, payload } = props;
    
    // For single series, add percentage label on top of the slice
    if (!hasTwoSeries) {
      // Calculate total for percentage
      const total = chartData.reduce((sum, entry) => sum + entry.count, 0);
      const percent = Math.round((payload.count / total) * 100);
      
      // Position for the percentage label (inside the slice)
      const insideRadius = (innerRadius + outerRadius) * 0.6;
      const insideX = cx + insideRadius * Math.cos(-midAngle * RADIAN);
      const insideY = cy + insideRadius * Math.sin(-midAngle * RADIAN);
      
      // Only show percentage label if the slice is large enough
      if (percent >= 3) {
        return (
          <>
            {/* Percentage label inside the slice */}
            <text
              x={insideX}
              y={insideY}
              fill="#FFFFFF"
              textAnchor="middle"
              dominantBaseline="central"
              fontSize={18}
              fontWeight="bold"
            >
              {`${percent}%`}
            </text>
            
            {/* Original label outside the slice */}
            <text
              x={cx + (outerRadius + 30) * Math.cos(-midAngle * RADIAN)}
              y={cy + (outerRadius + 30) * Math.sin(-midAngle * RADIAN)}
              fill={outerSeries.getColorByIndex(index)}
              textAnchor={cx + (outerRadius + 30) * Math.cos(-midAngle * RADIAN) > cx ? 'start' : 'end'}
              dominantBaseline="central"
              fontSize={17.3}
              fontWeight="bold"
            >
              {`${payload.name}-${payload.count}`}
            </text>
          </>
        );
      }
    }
    
    // Position labels outside the pie for both single and dual series
    // For multi-series, position outer labels further out than inner labels
    const radius = hasTwoSeries ? mainRadius * 1.25 : outerRadius + 30; // Position outer labels further out than inner labels
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // Use the series color for text
    const textColor = outerSeries.getColorByIndex(index);

    return (
      <text
        x={x}
        y={y}
        fill={textColor}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={17.3} // Increased by 20% from 14.4 (14.4 * 1.2 = 17.28)
        fontWeight="bold"
      >
        {`${payload.name}-${payload.count}`}
      </text>
    );
  };

  const renderInnerLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, index, payload } = props;
    // Only show label if slice is large enough
    if ((outerRadius - innerRadius) < 25) return null;
    
    // Calculate total for percentage
    const total = innerRingData.reduce((sum, entry) => sum + entry.count, 0);
    const percent = Math.round((payload.count / total) * 100);
    
    // Position for the percentage label (inside the slice)
    // Adjust the position to be more centered in the inner pie
    const insideRadius = outerRadius * 0.6;
    const insideX = cx + insideRadius * Math.cos(-midAngle * RADIAN);
    const insideY = cy + insideRadius * Math.sin(-midAngle * RADIAN);
    
    // Position labels outside the pie for inner series
    // Increase radius to ensure labels are outside the outer ring
    const radius = mainRadius * 1.20; 
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // Use the series color for text, same as outer labels
    const textColor = innerSeries.getColorByIndex(index);

    // Only show percentage if the slice is large enough
    if (percent >= 3) {
      return (
        <>
          {/* Percentage label inside the slice */}
          <text
            x={insideX}
            y={insideY}
            fill="#FFFFFF"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={16}
            fontWeight="bold"
          >
            {`${percent}%`}
          </text>
          
          {/* Name and count label outside the slice */}
          <text
            x={x}
            y={y}
            fill={textColor}
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            fontSize={15.6} // 13 * 1.2 = 15.6
            fontWeight="500"
          >
            {`${payload.name}-${payload.count}`}
          </text>
        </>
      );
    }
    
    // For smaller slices, just show the label without percentage
    return (
      <text
        x={x}
        y={y}
        fill={textColor}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={15.6} // 13 * 1.2 = 15.6
        fontWeight="500"
      >
        {`${payload.name}-${payload.count}`}
      </text>
    );
  };

  const handleLabelLine = (props) => {
    const { points, index, seriesIndex = 0 } = props;
    const series = seriesIndex === 0 ? outerSeries : innerSeries;
    return (
      <line
        x1={points[0].x}
        y1={points[0].y}
        x2={points[1].x}
        y2={points[1].y}
        fill={series.getColorByIndex(index)}
        style={{ stroke: series.getColorByIndex(index), strokeWidth: 1.8 }} // 1.5 * 1.2 = 1.8
      />
    );
  };

  // Custom label line handler for inner pie chart
  const handleInnerLabelLine = (props) => {
    const { points, index } = props;
    
    // Extend the line further out to reach the repositioned labels
    const extendedX = points[1].x + (points[1].x - points[0].x) * 2.0;
    const extendedY = points[1].y + (points[1].y - points[0].y) * 2.0;
    
    return (
      <line
        x1={points[0].x}
        y1={points[0].y}
        x2={extendedX}
        y2={extendedY}
        fill={innerSeries.getColorByIndex(index)}
        style={{ stroke: innerSeries.getColorByIndex(index), strokeWidth: 1.8 }}
      />
    );
  };

  // Calculate dimensions for the chart
  // Base the mainRadius on viewport dimensions for better responsiveness
  const viewportMin = Math.min(windowSize.width, windowSize.height);
  let mainRadius = viewportMin * 0.25; // Base size as 25% of the smaller viewport dimension
  
  // Apply minimum and maximum constraints
  mainRadius = Math.max(mainRadius, 180); // Minimum size
  mainRadius = Math.min(mainRadius, 300); // Maximum size
  
  // Still apply breakpoints for smaller screens
  if (windowSize.width < 1200) mainRadius = Math.min(mainRadius, viewportMin * 0.22);
  if (windowSize.width < 775) mainRadius = Math.min(mainRadius, viewportMin * 0.20);
  if (windowSize.width < 600) mainRadius = Math.min(mainRadius, viewportMin * 0.18);
  
  // Define dimensions for both rings with spacing
  // Make inner circle slightly smaller to accommodate thicker outer ring
  const innerOuterRadius = mainRadius * 0.88; // Main pie chart (inner ring) outer radius
  
  // Make outer ring 50% thicker
  // Original thickness was about 0.08 of mainRadius (1.05 - 0.97)
  // 50% thicker would be 0.12 of mainRadius
  const outerRingThickness = mainRadius * 0.12;
  const outerInnerRadius = hasTwoSeries ? mainRadius * 0.95 : 0; // Outer ring inner radius (0 for single series)
  const outerOuterRadius = hasTwoSeries 
    ? outerInnerRadius + outerRingThickness // Outer ring outer radius (thicker ring)
    : mainRadius; // Full pie for single series

  // Margin for pie slices (both inner and outer)
  const innerPieMargin = { top: 5, right: 5, bottom: 5, left: 5 };
  const outerPieMargin = { top: 2, right: 2, bottom: 2, left: 2 };

  return (
    <>
      <div className={classes.pieStyles} style={{ 
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center" 
      }}>
        <div style={{ height: "100%", flexGrow: 1 }}>
          <ResponsiveContainer width="100%" height="100%">
            <RechartsPieChart>
              {/* Inner pie - Status (only shown when we have two series) */}
              {hasTwoSeries && innerSeries && (
                <Pie
                  data={innerRingData}
                  dataKey="count"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={innerOuterRadius}
                  innerRadius={0}
                  fill="#8884d8"
                  label={renderInnerLabel}
                  labelLine={handleInnerLabelLine}
                  onClick={(entry, index) => handleSliceClick(entry, index, 1)} // 1 for inner series
                  cursor={config?.drilldown && innerSeriesDrilldownEnabled ? 'pointer' : 'default'}
                  onMouseEnter={handleInnerSliceMouseEnter}
                  onMouseLeave={handleInnerSliceMouseLeave}
                  isAnimationActive={false}
                  className={config?.drilldown && innerSeriesDrilldownEnabled ? classes.clickableSlice : ''}
                  paddingAngle={2} // Add padding between slices
                  margin={innerPieMargin}
                >
                  {innerRingData?.map((entry, index) => {
                    // Check if this inner slice should be highlighted
                    const isHighlighted = Array.isArray(mouseOverInner) 
                      ? mouseOverInner.includes(index) 
                      : mouseOverInner === index;
                    
                    return (
                      <Cell
                        key={`inner-cell-${index}`}
                        stroke={isHighlighted ? '#ffffff' : '#fff'} // Brighter white stroke when highlighted
                        strokeWidth={isHighlighted ? 3 : 1.8} // Thicker stroke when highlighted
                        fill={isHighlighted
                          ? innerSeries.getColorByIndex(index) + '88' // More vibrant highlight with 80% opacity
                          : innerSeries.getColorByIndex(index)}
                      />
                    );
                  })}
                </Pie>
              )}
              
              {/* Outer ring or full pie depending on number of series */}
              <Pie
                data={outerRingData}
                dataKey="count"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={outerOuterRadius}
                innerRadius={outerInnerRadius}
                fill="#8884d8"
                label={renderCustomizedLabel}
                onClick={(entry, index) => handleSliceClick(entry, index, 0)} // 0 for outer series
                cursor={config?.drilldown && outerSeriesDrilldownEnabled ? 'pointer' : 'default'}
                onMouseEnter={handleOuterSliceMouseEnter}
                onMouseLeave={handleOuterSliceMouseLeave}
                labelLine={handleLabelLine} // Use label lines for both single and dual series
                isAnimationActive={false}
                className={config?.drilldown && outerSeriesDrilldownEnabled ? classes.clickableSlice : ''}
                paddingAngle={hasTwoSeries ? 1.5 : 2} // Larger padding for full pie
                margin={outerPieMargin}
              >
                {outerRingData?.map((entry, index) => (
                  <Cell
                    key={`outer-cell-${index}`}
                    stroke={index === mouseOver ? '#ffffff' : '#fff'} // Brighter white stroke when highlighted
                    strokeWidth={index === mouseOver ? 3 : (hasTwoSeries ? 1.2 : 1.8)} // Thicker stroke when highlighted
                    fill={index === mouseOver 
                      ? outerSeries.getColorByIndex(index) + 'CC' // More vibrant highlight with 80% opacity
                      : outerSeries.getColorByIndex(index)}
                  />
                ))}
              </Pie>
              
              <Tooltip 
                formatter={(value, name, props) => {
                  const displayName = props.payload.parentName 
                    ? `${props.payload.parentName} - ${props.payload.name}`
                    : props.payload.name;
                  return [`${value}`, displayName];
                }}
                contentStyle={{ fontSize: '16.8px', padding: '12px' }} // 14 * 1.2 = 16.8, 10 * 1.2 = 12
              />
            </RechartsPieChart>
          </ResponsiveContainer>
        </div>
        {showLegend() && <Legend items={series} chartType="pie" position="right" />}
      </div>
    </>
  );
}; 