import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../redux/common/actions';
import { useReport } from '../ReportContext';
import { useFilters } from '../Filters/FilterContext';
import { getFilterSubtitle } from '../utils';

/**
 * Custom hook for loading and processing chart data
 * @returns {Object} Object containing isLoading, chartData, subtitle, and invalidFiltersMessage
 */
export const useChartData = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [subtitle, setSubtitle] = useState('Show All');
  const [invalidFiltersMessage, setInvalidFiltersMessage] = useState(null);
  const { config, drilldownParams } = useReport();
  const { isReady, filterValues, buildUrlParams } = useFilters();

  useEffect(() => {
    if (!isReady || !config.fetchData) return;

    const loadChartData = async () => {
      setIsLoading(true);
      setSubtitle(getFilterSubtitle(filterValues));
      setInvalidFiltersMessage(null);
      
      try {
        // Build URL params from filters
        const filterParams = buildUrlParams();
        
        // Combine filter params with drilldown params
        const params = {
          ...filterParams,
          ...drilldownParams
        };
        
        console.log(`Sending API params: ${JSON.stringify(params)}`);
  
        // Use the fetchData function from the config
        const response = await config.fetchData(params);
        
        if (response?.status) {
          const transformedData = config.transformData ? 
            config.transformData(response, filterValues) : 
            response.data;
          
          console.log(transformedData);
          setChartData(transformedData);
        } else if (response?.invalidFilters) {
          // Handle invalid filters case
          setInvalidFiltersMessage(response.message || 'Please select all required filters.');
          setChartData(null);
        }
      } catch (error) {
        dispatch(showError('Failed to load chart data'));
        console.error('Failed to load chart data:', error);
      }
      setIsLoading(false);
    };

    loadChartData();
  }, [filterValues, isReady, config, buildUrlParams, dispatch, drilldownParams]);

  return { isLoading, chartData, subtitle, invalidFiltersMessage };
}; 