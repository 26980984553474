// Feature flag constants to be used throughout the application
// This provides a centralized place to manage feature flag names
// and reduces the risk of typos when using feature flags

export const FEATURES = {
  // Analytics feature flags
  ANALYTICS: {
    EXPERIMENTAL_REPORTS: 'Feature.Experimental.Reports',
  },

  // Add other feature categories as needed
  // e.g., 
  // SERVICES: {
  //   NEW_SERVICE_FLOW: 'Feature.Services.NewFlow',
  // },
};

// Aliases for backward compatibility or convenient shortcuts
export const FEATURE_FLAGS = {
  EXPERIMENTAL_ANALYTICS: FEATURES.ANALYTICS.EXPERIMENTAL_REPORTS,
  // Add other aliases as needed
}; 