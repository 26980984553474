import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { useFilters } from './FilterContext';
import { makeStyles } from 'tss-react/mui';
import { useIntervals } from './hooks/useIntervals';

const useStyles = makeStyles()((theme) => ({
  intervalFilter: {
    '& .dropdown-content': {
      minWidth: '250px'
    }
  }
}));

export const IntervalFilter = () => {
  const { classes } = useStyles();
  const { filterValues, updateFilter, isRequired, isShowAll } = useFilters();
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  
  const modelVersionId = filterValues.model?.value?.activeModelVersionID;
  const roleId = filterValues.role?.value?.roleId;
  
  const intervals = useIntervals(modelVersionId, roleId, isShowAll('interval'));

  // Reset interval when model or role changes
  useEffect(() => {
    function defaultValue() {
      return isShowAll('interval') ? { id: '', name: 'Show All' } : {};
    }

    updateFilter('interval', defaultValue(), { intervalId: '' });
  }, [filterValues.model, filterValues.role]);

  const handleIntervalChange = (item) => {
    // Updated to use modelVersionIntervalId instead of intervalId
    updateFilter('interval', item, { modelVersionIntervalId: item.id || '' });
  };

  return (
    <Grid item lg={3} md={6} xs={12} className={classes.intervalFilter}>
      <DyanamicDropdown
        dropDownActiveWidth="auto"
        dataSource={intervals}
        dataHeader={[
          {
            id: 1,
            name: `${customCaptions?.interval ?? "Interval"}`
          }
        ]}
        showHeader
        columns={[{ id: 1, name: "name" }]}
        columnsMinWidths={[250]}
        placeholder={`Select ${customCaptions?.interval}`}
        width="100%"
        onChange={handleIntervalChange}
        selectdValueToshow="name"
        selectedValue={filterValues.interval?.value}
        label={`Filter by ${customCaptions?.interval}`}
        isServerSide={false}
        icon={<FilterListIcon style={{ color: "rgb(48, 122, 215)" }} />}
        required={isRequired('interval')}
        showBorderColor
      />
    </Grid>
  );
}; 
