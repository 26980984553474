import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { getConditionMonitoringQuestions } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../redux/common/actions';
import { useFilters } from './FilterContext';
import { serviceMonitorQuestionHeader } from "constants/serviceDetails";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  questionFilter: {
    '& .dropdown-content': {
      minWidth: '650px'
    }
  }
}));

export const QuestionFilter = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([]);
  const { filterValues, updateFilter, config } = useFilters();
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));

  // Initialize question filter
  useEffect(() => {
    if (!filterValues.question) {
      updateFilter('question', { id: '', questionName: 'Show All' }, { questionId: '' });
    }
  }, []);

  useEffect(() => {
    const loadQuestions = async () => {
      if (!filterValues.model?.params?.activeModelVersionID) return;
      
      try {
        const response = await getConditionMonitoringQuestions({ 
          modelVersionId: filterValues.model.params.activeModelVersionID,
          roleId: filterValues.role?.params?.roleId || '',
          intervalId: filterValues.interval?.params?.intervalId || ''
        });
        if (response?.status) {
          setQuestions([
            { id: '', questionName: 'Show All' },
            ...response.data
          ]);
        }
      } catch (error) {
        dispatch(showError('Failed to load questions'));
      }
    };
    loadQuestions();
  }, [filterValues.model, filterValues.role, filterValues.interval]);

  // Reset question when model, role, or interval changes
  useEffect(() => {
    updateFilter('question', { id: '', questionName: 'Show All' }, { questionId: '' });
  }, [filterValues.model, filterValues.role, filterValues.interval]);

  const handleQuestionChange = (item) => {
    // Ensure we're passing the correct structure with additional IDs
    updateFilter('question', item, { 
      questionId: item.id || '',
      modelVersionTaskQuestionId: item.modelVersionTaskQuestionID || '',
      modelVersionZoneId: item.modelVersionZoneID || '',
      modelVersionStageId: item.modelVersionStageID || ''
    });
  };

  return (
    <Grid item lg={3} md={6} xs={12} className={classes.questionFilter}>
      <DyanamicDropdown
        dropDownActiveWidth="auto"
        dataSource={questions}
        dataHeader={serviceMonitorQuestionHeader(customCaptions, "A")}
        showHeader
        columns={[
          { id: 1, name: "stageName" },
          { id: 2, name: "zoneName" },
          { id: 3, name: "taskName" },
          { id: 4, name: "questionName" }
        ]}
        columnsMinWidths={[160, 160, 160, 160]}
        placeholder={`Select ${customCaptions?.question}`}
        width="100%"
        onChange={handleQuestionChange}
        selectdValueToshow="questionName"
        selectedValue={filterValues.question?.value}
        label={`Filter by ${customCaptions?.question}`}
        isServerSide={false}
        icon={<FilterListIcon style={{ color: "rgb(48, 122, 215)" }} />}
        required={config.filters.question?.required}
        showBorderColor
      />
    </Grid>
  );
};
