import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  legendContainer: {
    display: 'flex',
    gap: '16px',
    marginTop: '16px'
  },
  horizontalLegend: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalLegend: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: '0px',
    marginTop: '0',
    maxWidth: '130px',
    overflowY: 'auto',
    maxHeight: '60vh',
    textWrap: 'wrap'
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  verticalItem: {
    width: '100%',
    marginBottom: '12px',
    alignItems: 'flex-start'
  },
  lineContainer: {
    position: 'relative',
    width: '24px',
    height: '12px',
    display: 'flex',
    alignItems: 'center'
  },
  line: {
    width: '100%',
    height: '2px'
  },
  dot: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '8px',
    height: '8px',
    borderRadius: '50%'
  },
  bar: {
    width: '12px',
    height: '12px',
    borderRadius: '2px'
  },
  verticalLabel: {
    maxWidth: 'calc(100% - 32px)',
    wordBreak: 'break-word',
    lineHeight: 1.2,
    marginTop: '2px'
  }
}));

const LineIcon = ({ color, isVertical }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.lineContainer} sx={{ marginTop: isVertical ? '3px' : '0' }}>
      <Box className={classes.line} sx={{ backgroundColor: color }} />
      <Box className={classes.dot} sx={{ backgroundColor: color }} />
    </Box>
  );
};

const BarIcon = ({ color, isVertical }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.bar} sx={{ marginTop: isVertical ? '3px' : '0', backgroundColor: color }} />
  );
};

export const Legend = ({ items, chartType = 'line', position = 'bottom' }) => {
  const { classes, cx } = useStyles();

  // Guard against null or undefined items
  if (!items || !Array.isArray(items)) {
    return null;
  }

  // For multi-bar-line chart, use the type property from each item
  const getIconForItem = (item, chartType) => {
    if (chartType === 'multi-bar-line') {
      return item.type === 'line' ? LineIcon : BarIcon;
    }
    return chartType.includes('bar') ? BarIcon : LineIcon;
  };
  
  // Sort items alphabetically by label with null checks
  const sortedItems = [...items].sort((a, b) => {
    // Handle cases where label might be null or undefined
    const labelA = a?.label || '';
    const labelB = b?.label || '';
    return labelA.localeCompare(labelB);
  });

  const isVertical = position === 'right';

  return (
    <Box 
      className={cx(
        classes.legendContainer,
        isVertical ? classes.verticalLegend : classes.horizontalLegend
      )}
    >
      {sortedItems.map((item, index) => {
        const IconComponent = getIconForItem(item, chartType);
        return (
          <Box 
            key={index} 
            className={cx(
              classes.legendItem,
              isVertical && classes.verticalItem
            )}
          >
            <IconComponent color={item.getColorByIndex(index) || '#cccccc'} isVertical={isVertical} />
            <Typography 
              variant="body2" 
              color="text.secondary"
              className={isVertical ? classes.verticalLabel : undefined}
            >
              {item.label || 'Unknown'}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}; 