import React, { createContext, useContext, useMemo, useEffect, useState } from 'react';
import { getSiteApplicationDetail } from 'services/clients/sites/siteApplications/siteApplicationDetails';

const isEnabled = (flags, flagName) => flags.includes(flagName);
const hasAnyEnabled = (flags, flagNames) => flagNames.some(flag => flags.includes(flag));
const hasAllEnabled = (flags, flagNames) => flagNames.every(flag => flags.includes(flag));

const FeatureFlagsContext = createContext({
  flags: [],
  isEnabled: (flagName) => isEnabled([], flagName),
  hasAnyEnabled: (flagNames) => hasAnyEnabled([], flagNames),
  hasAllEnabled: (flagNames) => hasAllEnabled([], flagNames),
});

export function FeatureFlagsProvider({ children }) {
  const [featureFlags, setFeatureFlags] = useState([]);
  
  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const data = JSON.parse(sessionStorage.getItem("me") || localStorage.getItem("me"));
        const siteAppID = data?.siteAppID;
        
        if (siteAppID) {
          const result = await getSiteApplicationDetail(siteAppID);
          if (result.status) {
            setFeatureFlags(result.data.application.featureFlags || []);
          }
        }
      } catch (e) {
        console.error('Failed to fetch feature flags:', e);
        setFeatureFlags([]);
      }
    };

    fetchFeatureFlags();
  }, []);

  const contextValue = useMemo(() => ({
    flags: featureFlags,
    isEnabled: (flagName) => isEnabled(featureFlags, flagName),
    hasAnyEnabled: (flagNames) => hasAnyEnabled(featureFlags, flagNames),
    hasAllEnabled: (flagNames) => hasAllEnabled(featureFlags, flagNames),
  }), [featureFlags]);

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlags() {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }
  return context;
} 