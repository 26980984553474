import { useMemo } from 'react';

/**
 * Color palette definitions
 * These colors are organized into categories for different use cases
 */
const CHART_COLORS = {
  // Primary palette - general purpose colors for most charts
  primary: [
    '#3b679b', '#963835', '#76923C', '#139650', '#FAB15A',
    '#5A4573', '#F0565E', '#7FA4D0', '#096B19', '#56CEF0',
    '#E3DC5D', '#E3A05D', '#AA56F0', '#11D631', '#4A1B2A',
    '#26063B', '#789331', '#2DA193', '#CEA007', '#24130B',
    '#A11205', '#5EC240',
  ],
  
  // Greys palette - alternating darker and lighter shades for better distinction
  greys: [
    '#9FA3AD', '#3A3E47', '#878B96', '#4D5057', '#767A86', 
    '#5F636D', '#686C79', '#6E7380', '#5A5E6A', '#7D8291',
    '#4A4E59', '#474B56', '#8C909C', '#555A66', '#7F838F',
    '#636873', '#939AA7', '#717683',
  ],
  
  // Positive sentiment colors (greens, blues)
  positive: [
    '#139650', '#096B19', '#11D631', '#5EC240', '#76923C', 
    '#789331', '#2DA193', '#3b679b', '#7FA4D0', '#56CEF0',
  ],
  
  // Neutral sentiment colors (yellows, oranges, purples)
  neutral: [
    '#FAB15A', '#E3DC5D', '#E3A05D', '#CEA007', 
    '#5A4573', '#AA56F0', '#26063B',
  ],
  
  // Negative sentiment colors (reds)
  negative: [
    '#E31D38', '#F0565E', '#963835', '#CD6155', '#A11205',
  ],
  
  // Sequential palette for continuous data
  sequential: [
    '#d0e9ff', '#a8d5ff', '#7ec2ff', '#54aeff', '#2a9aff', 
    '#0086ff', '#0068c6', '#004a8d', '#002c54', '#000e1b',
  ],
  
  // Diverging palette for data with meaningful midpoint
  diverging: [
    '#963835', '#c25d5a', '#e88e8c', '#ffbdbc', '#f2f2f2', 
    '#bce4d8', '#7fd4bc', '#41c3a0', '#139650',
  ],
  
  // Categorical palette optimized for accessibility
  accessible: [
    '#3b679b', '#76923C', '#FAB15A', '#F0565E', '#5A4573',
    '#56CEF0', '#E3DC5D', '#2DA193', '#963835', '#139650',
  ],
};

/**
 * Custom hook for managing chart colors
 * Provides consistent color palettes and utility functions for charts
 */
const useChartColors = () => {
  const colorUtils = useMemo(() => ({
    /**
     * Get all available colors
     * @returns {string[]} All colors from the primary palette
     */
    getAllColors: () => CHART_COLORS.primary,
    
    /**
     * Get colors by category
     * @param {string} category - Category name ('primary', 'positive', 'neutral', 'negative', etc.)
     * @returns {string[]} Colors in the specified category
     */
    getColorsByCategory: (category) => {
      if (!CHART_COLORS[category]) {
        console.warn(`Category "${category}" not found, returning primary colors`);
        return CHART_COLORS.primary;
      }
      return CHART_COLORS[category];
    },
    
    /**
     * Get a specific color by index from a category
     * @param {number} index - Index of the color
     * @param {string} category - Category name (defaults to 'primary')
     * @returns {string} Color at the specified index (wraps around if index exceeds array length)
     */
    getColorByIndex: (index, category = 'primary') => {
      const colors = CHART_COLORS[category] || CHART_COLORS.primary;
      return colors[index % colors.length];
    },
    
    /**
     * Generate colors for a dataset
     * @param {number} count - Number of colors needed
     * @param {string} category - Category name (defaults to 'primary')
     * @returns {string[]} Array of colors
     */
    generateColors: (count, category = 'primary') => {
      const colors = CHART_COLORS[category] || CHART_COLORS.primary;
      return Array.from({ length: count }, (_, i) => colors[i % colors.length]);
    },
    
    /**
     * Map data items to colors
     * @param {Array} data - Array of data items
     * @param {string} category - Category name (defaults to 'primary')
     * @returns {Object} Mapping of data items to colors
     */
    mapDataToColors: (data, category = 'primary') => {
      const colors = CHART_COLORS[category] || CHART_COLORS.primary;
      return data.reduce((acc, item, index) => {
        const key = item.id || item.name || index;
        acc[key] = colors[index % colors.length];
        return acc;
      }, {});
    },
    
    /**
     * Create legend items with colors
     * @param {Array} data - Array of data items
     * @param {string} category - Category name (defaults to 'primary')
     * @returns {Array} Legend items with keys, labels, and colors
     */
    createLegendItems: (data, category = 'primary') => {
      const colors = CHART_COLORS[category] || CHART_COLORS.primary;
      return data.map((item, index) => ({
        key: item.id?.toString() || item.key || index.toString(),
        label: item.name || item.label,
        color: colors[index % colors.length]
      }));
    },
    
    /**
     * Get all available color categories
     * @returns {string[]} Array of category names
     */
    getCategories: () => Object.keys(CHART_COLORS),
    
    /**
     * Get all color palettes
     * @returns {Object} All color palettes
     */
    getAllPalettes: () => CHART_COLORS,
  }), []);

  return colorUtils;
};

export default useChartColors; 