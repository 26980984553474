import React from 'react';
import { Box, Button, Tooltip, useTheme } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useChartExport } from '../hooks';

export const ExportButton = ({ title, chartRef, containerRef }) => {
  const theme = useTheme();
  
  // Use the chart export hook
  const { handleExport, isExporting } = useChartExport({
    title,
    quality: 1.0,
    backgroundColor: '#ffffff',
    chartRef,
    containerRef
  });

  return (
    <Tooltip title="Export as PNG">
      <Button
        variant="outlined"
        size="small"
        onClick={handleExport}
        disabled={isExporting}
        sx={{
          minWidth: '40px',
          height: '32px',
          borderColor: theme.palette.grey[300],
          color: 'rgb(48,122,215)',
          '&:hover': {
            backgroundColor: 'rgba(48,122,215,0.1)',
            borderColor: 'rgb(48,122,215)'
          }
        }}
      >
        {isExporting ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px', height: '24px' }}>
            <Box
              sx={{
                width: '16px',
                height: '16px',
                border: '2px solid rgba(48,122,215,0.2)',
                borderTop: '2px solid rgb(48,122,215)',
                borderRadius: '50%',
                animation: 'spin 1s linear infinite',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' }
                }
              }}
            />
          </Box>
        ) : (
          <FileDownloadIcon fontSize="small" />
        )}
      </Button>
    </Tooltip>
  );
}; 