import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { useFilters } from './FilterContext';
import { makeStyles } from 'tss-react/mui';
import { useRoles } from './hooks/useRoles';

const useStyles = makeStyles()((theme) => ({
  roleFilter: {
    '& .dropdown-content': {
      minWidth: '250px'
    }
  }
}));

export const RoleFilter = () => {
  const { classes } = useStyles();
  const { filterValues, updateFilter, isRequired, isShowAll } = useFilters();
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  
  const modelVersionId = filterValues.model?.value?.activeModelVersionID;
  const roles = useRoles(modelVersionId, isShowAll('role'));

  // Initialize and reset role when model changes
  useEffect(() => {
    function defaultValue() {
      return isShowAll('role') ? { id: '', name: 'Show All' } : {};
    }

    updateFilter('role', defaultValue(), { roleId: '' });
  }, [filterValues.model]);

  const handleRoleChange = (item) => {
    // Ensure we're passing the correct structure
    updateFilter('role', item, { roleId: item.id || '' });
  };

  return (
    <Grid item lg={3} md={6} xs={12} className={classes.roleFilter}>
      <DyanamicDropdown
        dropDownActiveWidth="auto"
        dataSource={roles}
        dataHeader={[
          {
            id: 1,
            name: `${customCaptions?.role ?? "Role"}`
          }
        ]}
        showHeader
        columns={[{ id: 1, name: "name" }]}
        columnsMinWidths={[250]}
        placeholder={`Select ${customCaptions?.role}`}
        width="100%"
        onChange={handleRoleChange}
        selectdValueToshow="name"
        selectedValue={filterValues.role?.value}
        label={`Filter by ${customCaptions?.role}`}
        isServerSide={false}
        icon={<FilterListIcon style={{ color: "rgb(48, 122, 215)" }} />}
        required={isRequired('role')}
        showBorderColor
      />
    </Grid>
  );
}; 
