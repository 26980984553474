import React from 'react';
import { TimeframeFilter } from './TimeframeFilter';
import { DepartmentFilter } from './DepartmentFilter';
import { ModelFilter } from './ModelFilter';
import { RiskRatingFilter } from './RiskRatingFilter';
import { RoleFilter } from './RoleFilter';
import { AssetFilter } from './AssetFilter';
import { IntervalFilter } from './IntervalFilter';
import { QuestionFilter } from './QuestionFilter';
import { DefectStatusTypeFilter } from './DefectStatusTypeFilter';
import { DefectTypeFilter } from './DefectTypeFilter';
import { WorkOrderFilter } from './WorkOrderFilter';

const FILTER_COMPONENTS = {
  timeframe: TimeframeFilter,
  department: DepartmentFilter,
  model: ModelFilter,
  riskRatings: RiskRatingFilter,
  role: RoleFilter,
  asset: AssetFilter,
  interval: IntervalFilter,
  question: QuestionFilter,
  defectStatusType: DefectStatusTypeFilter,
  defectType: DefectTypeFilter,
  workOrder: WorkOrderFilter,
};

export const createFilter = (filterType) => {
  const FilterComponent = FILTER_COMPONENTS[filterType];
  if (!FilterComponent) return null;

  return <FilterComponent key={filterType} />;
}; 