import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { getModelAssetsAvailable } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../redux/common/actions';
import { useFilters } from './FilterContext';

export const AssetFilter = () => {
  const dispatch = useDispatch();
  const [assets, setAssets] = useState([]);
  const { filterValues, updateFilter, config } = useFilters();
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));

  // Initialize asset filter
  useEffect(() => {
    if (!filterValues.asset) {
      updateFilter('asset', { id: '', name: 'Show All' }, { siteAssetId: '' });
    }
  }, []);

  useEffect(() => {
    const loadAssets = async () => {
      if (!filterValues.model?.value?.id) return;
      
      try {
        const response = await getModelAssetsAvailable({ 
          modelId: filterValues.model.value.id 
        });
        if (response?.status) {
          setAssets([
            { id: '', name: 'Show All' },
            ...response.data.map(a => ({
              id: a.siteAssetID,
              name: a.name
            }))
          ]);
        }
      } catch (error) {
        dispatch(showError('Failed to load assets'));
      }
    };
    loadAssets();
  }, [filterValues.model]);

  // Reset asset when model changes
  useEffect(() => {
    if (filterValues.model?.value) {
      updateFilter('asset', { id: '', name: 'Show All' }, { siteAssetId: '' });
    }
  }, [filterValues.model]);

  // Don't show if model type doesn't match
  if (config.filters.asset?.show && !config.filters.asset.show(filterValues.model?.value)) {
    return null;
  }

  return (
    <Grid item lg={3} md={6} xs={12}>
      <DyanamicDropdown
        dataSource={assets}
        dataHeader={[
          {
            id: 1,
            name: `${customCaptions?.asset ?? "Asset"}`,
          },
        ]}
        showHeader
        columns={[{ id: 1, name: "name" }]}
        columnsMinWidths={[140, 140, 140, 140, 140]}
        placeholder={`Select ${customCaptions?.asset}`}
        width="100%"
        onChange={item => updateFilter('asset', item, { siteAssetId: item.id })}
        selectdValueToshow="name"
        selectedValue={filterValues.asset?.value}
        label={`Filter by ${customCaptions?.asset}`}
        isServerSide={false}
        icon={<FilterListIcon style={{ color: "rgb(48, 122, 215)" }} />}
        required={config.filters.asset?.required}
        showBorderColor
      />
    </Grid>
  );
}; 
