/**
 * Custom hook for transforming API data into a format suitable for recharts.
 * 
 * This hook handles two main data formats:
 * 1. Objects with series data (keys representing series names)
 * 2. Raw arrays that are already in a suitable format
 */
import { useMemo } from 'react';

/**
 * Merges series data from separate arrays into a single array of objects.
 *
 * Each series in `rawData` should be an array of objects that includes at least a
 * `label` property (which will be used as the x-axis identifier) and a `count` property.
 * The function iterates over all series (without assuming specific keys) and combines
 * them based on the common `label`. Missing series values for a given label are filled with 0.
 *
 * @param {Object} rawData - An object where each key represents a series (e.g., "outstanding", "completed")
 *                           and its value is an array of data points. Each data point is an object with
 *                           at least a `label` and `count` property.
 * @returns {Array} - An array of merged data objects sorted by the label.
 *
 * @example
 * // Input:
 * const input = {
 *   outstanding: [
 *     { label: "2024-02", count: 2 },
 *     { label: "2024-03", count: 7 }
 *   ],
 *   completed: [
 *     { label: "2024-02", count: 1 },
 *     { label: "2024-03", count: 5 }
 *   ]
 * };
 *
 * // Calling the function:
 * const mergedData = mergeSeriesData(input);
 *
 * // Output:
 * // [
 * //   { label: "2024-02", outstanding: 2, completed: 1 },
 * //   { label: "2024-03", outstanding: 7, completed: 5 }
 * // ]
 */
function mergeSeriesData(rawData) {
  const merged = {};

  // Iterate over each series in the rawData object
  Object.keys(rawData).forEach(seriesKey => {
    // Loop through each data point in the series
    rawData[seriesKey].forEach(item => {
      const label = item.label; // using the 'label' field to merge data points
      if (!merged[label]) {
        merged[label] = { label };
      }
      // Assign the count value to the property corresponding to the series key
      merged[label][seriesKey] = item.count;
    });
  });

  // Fill missing values with 0 for every series key for each merged data point
  Object.values(merged).forEach(dataPoint => {
    Object.keys(rawData).forEach(seriesKey => {
      if (dataPoint[seriesKey] === undefined) {
        dataPoint[seriesKey] = 0;
      }
    });
  });

  // Convert the merged object to an array and sort by the label (assuming labels are sortable, e.g., "YYYY-MM")
  return Object.values(merged).sort((a, b) => a.label.localeCompare(b.label));
}

/**
 * Ensures each object in the array has a label property, copying from name if needed.
 * 
 * @param {Array} data - Array of data objects
 * @returns {Array} - Array with ensured label properties
 */
function ensureLabels(data) {
  return data.map(item => {
    if (!item.label && item.name) {
      return { ...item, label: item.name };
    }
    return item;
  });
}

/**
 * Custom hook that transforms data for use with recharts.
 * 
 * @param {Object|Array} data - The data to transform. Can be either:
 *                             - An object where keys represent series names and values are arrays of data points
 *                             - An array that's already in the correct format for recharts
 * @returns {Array} - Transformed data ready for recharts
 */
function useTransformData(data) {
  return useMemo(() => {
    // If data is null or undefined, return an empty array
    if (!data) return [];
    
    // If data is an array, ensure each object has a label property
    if (Array.isArray(data)) {
      return ensureLabels(data);
    }
    
    // If data is an object with keys, transform it using mergeSeriesData
    if (typeof data === 'object' && Object.keys(data).length > 0) {
      return mergeSeriesData(data);
    }
    
    // Default case: return empty array
    return [];
  }, [data]);
}

export default useTransformData; 