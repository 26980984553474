import React from 'react';
import { format, parse, parseISO, isFirstDayOfMonth, getDate } from 'date-fns';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  customTooltip: {
    background: "#e9e9e9",
    padding: "6px 12px",
    border: "1px solid",
    borderRadius: "3px",
  },
  toolTipLabel: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "& p": {
      margin: "0px !important",
    },
  },
  colorBox: {
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    border: "2px solid transparent",
  },
  label: {
    margin: 0,
  },
  clickableElement: {
    cursor: 'pointer',
  }
}));

/**
 * Hook for common chart formatting utilities
 * @param {string} timeGrouping - The time grouping type ('Hourly', 'Daily', 'Weekly', 'Monthly')
 * @returns {Object} Formatting utilities for charts
 */
const useChartFormatting = (timeGrouping = 'Monthly') => {
  const { classes } = useStyles();
  
  /**
   * Format X-axis tick values based on time grouping
   * @param {string} value - The tick value to format
   * @param {number} index - The index of the tick
   * @param {Array} ticks - All ticks in the axis
   * @returns {string} Formatted tick value
   */
  const formatXAxisTick = (value, index, ticks) => {
    // If value is already a formatted label, return it
    if (typeof value === 'string' && value.includes('-')) {
      try {
        // Try to parse as ISO date string
        const date = parseISO(value);
        
        // Format based on timeGrouping
        switch (timeGrouping) {
          case 'Hourly':
            return format(date, 'HH:mm, MMM d');
          case 'Daily':
            // For daily view, only show month on first day of month or first tick
            if (isFirstDayOfMonth(date) || index === 0) {
              return format(date, 'MMM d');
            } else {
              // Just show the day number for other days
              return format(date, 'd');
            }
          case 'Weekly':
            return format(date, "'Week of' MMM d, yyyy");
          case 'Monthly':
          default:
            return format(date, 'MMM yyyy');
        }
      } catch (e) {
        // If parsing fails, return the original value
        return value;
      }
    }
    
    // Legacy format handling (M/yyyy)
    try {
      const date = parse(value, 'M/yyyy', new Date());
      return format(date, 'MMM yyyy');
    } catch (e) {
      return value;
    }
  };

  /**
   * Format a date for tooltip display
   * @param {string} label - The date label to format
   * @returns {string} Formatted date for tooltip
   */
  const formatTooltipDate = (label) => {
    let formattedDate = label;
    
    try {
      if (label.includes('-')) {
        const date = parseISO(label);
        switch (timeGrouping) {
          case 'Hourly':
            formattedDate = format(date, 'HH:mm, MMMM d, yyyy');
            break;
          case 'Daily':
            formattedDate = format(date, 'MMMM d, yyyy');
            break;
          case 'Weekly':
            formattedDate = format(date, "'Week of' MMMM d, yyyy");
            break;
          case 'Monthly':
            formattedDate = format(date, 'MMMM yyyy');
            break;
          default:
            formattedDate = label;
        }
      } else if (label.includes('/')) {
        const date = parse(label, 'M/yyyy', new Date());
        formattedDate = format(date, 'MMMM yyyy');
      }
    } catch (e) {
      // If parsing fails, use the original label
      formattedDate = label;
    }
    
    return formattedDate;
  };

  /**
   * Custom tooltip component for charts
   */
  const CustomTooltip = ({ active, payload, label, showToolTip, legendItems }) => {
    if (active && payload && payload.length && showToolTip) {
      const formattedDate = formatTooltipDate(label);
      
      return (
        <div className={classes.customTooltip}>
          <b className={classes.label} style={{ display: 'block', marginBottom: '8px' }}>
            {formattedDate}
          </b>
          {payload.map((entry, index) => {
            // Try to get the label from legendItems if available
            const itemLabel = legendItems && legendItems[index] 
              ? legendItems[index].label 
              : entry.name;
              
            return (
              <div key={index} className={classes.toolTipLabel}>
                <div
                  className={classes.colorBox}
                  style={{ 
                    background: entry.fill || entry.stroke,
                    borderColor: entry.stroke || entry.fill,
                  }}
                />
                <label style={{ fontSize: '14px' }}>
                  {itemLabel}:
                  <span style={{ fontWeight: 'bold', marginLeft: '4px' }}>{entry.value}</span>
                </label>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  /**
   * Get custom X-axis props for better date formatting
   * @returns {Object} Props to apply to XAxis component
   */
  const getCustomXAxisProps = () => {
    if (timeGrouping === 'Daily') {
      return {
        tick: (props) => {
          const { x, y, payload } = props;
          try {
            const date = parseISO(payload.value);
            const isFirstDay = isFirstDayOfMonth(date) || payload.index === 0;
            
            return (
              <g transform={`translate(${x},${y})`}>
                <text 
                  x={0} 
                  y={0} 
                  dy={16} 
                  textAnchor="middle" 
                  fill="#666"
                >
                  {getDate(date)}
                </text>
                {isFirstDay && (
                  <text 
                    x={0} 
                    y={0} 
                    dy={36} 
                    textAnchor="middle" 
                    fill="#666"
                    fontWeight="bold"
                  >
                    {format(date, 'MMM')}
                  </text>
                )}
              </g>
            );
          } catch (e) {
            return (
              <g transform={`translate(${x},${y})`}>
                <text 
                  x={0} 
                  y={0} 
                  dy={16} 
                  textAnchor="middle" 
                  fill="#666"
                >
                  {payload.value}
                </text>
              </g>
            );
          }
        }
      };
    }
    
    return {};
  };

  return {
    formatXAxisTick,
    formatTooltipDate,
    CustomTooltip,
    getCustomXAxisProps,
    classes
  };
};

export default useChartFormatting; 