import React from 'react';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { useReport } from '../ReportContext';
import ColourConstants from 'helpers/colourConstants';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
	header: {
		marginTop: 40,
		color: ColourConstants.commonText,
		fontSize: 20,
		fontWeight: "bold",
	},
	normalLink: {
		color: ColourConstants.activeLink,
		fontSize: '0.875rem',
		textDecoration: "underline",
		"&:hover": {
			cursor: "pointer",
		},
	},
	activeLink: {
		color: ColourConstants.commonText,
		fontSize: '0.875rem',
		"&:hover": {
			textDecoration: "none",
		},
	},
	crumbText: {
		fontSize: '0.875rem',
		color: ColourConstants.commonText,
	},
	container: {
		display: "inline-flex",
		alignItems: "center",
	},
}));

export const DrilldownNavigation = () => {
	const { classes } = useStyles();
	const { navigateToLevel, navigationPath } = useReport();

	const DefaultSeparator = () => <span className={classes.crumbText}>{">"}</span>;

	return (
		<div className={classes.container} style={{ display: "inline-flex" }}>
			<Breadcrumbs aria-label="breadcrumb" separator={<DefaultSeparator />}>
				{navigationPath.map((item, idx) => {
					const isLastItem = idx === navigationPath.length - 1;
					
					return isLastItem ? (
						<Typography key={`breadcrumb-${idx}`} className={classes.activeLink}>
							{item.label}
						</Typography>
					) : (
						<Link
							key={`breadcrumb-${idx}`}
							className={classes.normalLink}
							onClick={() => navigateToLevel(item.level || idx)}
						>
							{item.label}
						</Link>
					);
				})}
			</Breadcrumbs>
		</div>
	);
};
