import React from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { Apis } from "services/api";
import { getMissingPartsTools } from "services/analytics";
import useChartColors from '../ReportChart/hooks/useChartColors';

const MissingPartsToolsReport = () => {
  const { customCaptions, application } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  const { getAllColors } = useChartColors();

  const chartConfig = {
    id: 'missing-parts-tools',
    title: `Missing ${
      application.showParts
        ? `${customCaptions.partPlural ?? "Parts"} and `
        : ""
    }${customCaptions.toolPlural} by Reason`,
    fetchData: async (params) => {
      return await getMissingPartsTools(params);
    },
    allowedChartTypes: ['vertical-bar', 'horizontal-bar', 'pie', 'tree-map'],
    defaultChartType: 'vertical-bar',
    series: [
      { 
        label: 'Count', 
        key: 'count', 
        colors: getAllColors() 
      }
    ],
    transformData: (response) => {
      return response?.data?.data;
    },
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: true
    },
    drilldown: {
      defaultChartType: 'vertical-bar',
      allowedChartTypes: ['vertical-bar', 'horizontal-bar', 'pie'],
      series: [
        { 
          label: `${customCaptions.part || 'Part'}/${customCaptions.tool || 'Tool'} Count`, 
          key: 'count', 
          legend: false
        }
      ],
      breadcrumbLabel: `${customCaptions.part || 'Part'}/${customCaptions.tool || 'Tool'} Count`,
      getParams: (dataPoint) => {
        return {
          missingPartToolId: dataPoint.id
        };
      }
    }
  };

  return (
    <ReportPage 
      title={chartConfig.title}
      chartConfig={chartConfig}
    />
  );
};

export default MissingPartsToolsReport; 
