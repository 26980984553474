import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { defectStatusTypes } from 'helpers/constants';
import { useFilters } from './FilterContext';

export const DefectStatusTypeFilter = () => {
  const { filterValues, updateFilter } = useFilters();
  
  // Initialize with default value
  useEffect(() => {
    updateFilter('defectStatusType', { id: '', name: 'Show All' });
  }, []);

  // Format the defect status types for the dropdown
  const defectStatusTypeOptions = [
    { id: '', name: 'Show All' },
    { id: defectStatusTypes[0]?.value, name: defectStatusTypes[0]?.label },
    { id: defectStatusTypes[1]?.value, name: defectStatusTypes[1]?.label },
  ];

  if (!filterValues.defectStatusType) {
    return null;
  }

  return (
    <Grid item lg={3} md={6} xs={12}>
      <DyanamicDropdown
        dataSource={defectStatusTypeOptions}
        columns={[{ name: 'name', id: 1 }]}
        showHeader={false}
        width="100%"
        placeholder="Select Defect Status Type"
        onChange={item => updateFilter('defectStatusType', item, { defectStatusType: item.id })}
        selectdValueToshow="name"
        selectedValue={filterValues.defectStatusType?.value}
        label="Filter by Defect Status Type"
        icon={<FilterListIcon style={{ color: 'rgb(48,122,215)' }} />}
        required={false}
        showBorderColor
      />
    </Grid>
  );
}; 