import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Icon from '../../../../../components/Elements/Icon';

export const InvalidFiltersMessage = ({ message }) => {
  const theme = useTheme();
  
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
        height: '100%',
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(4),
          borderRadius: theme.spacing(1),
          backgroundColor: theme.palette.grey[50],
          maxHeight: '400px',
          maxWidth: '400px',
        }}
      >
        <Icon 
          name="FilterIcon" 
          height="100px" 
          width="100px" 
          style={{ 
            marginBottom: theme.spacing(2),
            opacity: 0.8,
          }}
        />
        <Typography 
          variant="h6" 
          color="textSecondary"
          align="center"
          sx={{ 
            fontWeight: 500,
            marginBottom: theme.spacing(1)
          }}
        >
          Invalid Filters
        </Typography>
        <Typography 
          variant="body2" 
          color="textSecondary"
          align="center"
        >
          {message || "Please select all required filters to view the data."}
        </Typography>
      </Box>
    </Box>
  );
}; 