import React, { useState, useCallback } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { makeStyles } from "tss-react/mui";
import { Legend } from '../Layout';
import { useReport } from '../ReportContext';
import { useChartFormatting, useTimeGrouping, useTransformData, useSeries } from '../hooks';

const useStyles = makeStyles()((theme) => ({
  customTooltip: {
    background: "#e9e9e9",
    padding: "6px 12px",
    border: "1px solid",
    borderRadius: "3px",
  },
  toolTipLabel: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "& p": {
      margin: "0px !important",
    },
  },
  colorBox: {
    width: "14px",
    height: "14px",
  },
  label: {
    margin: 0,
  },
  clickableElement: {
    cursor: 'pointer',
  }
}));

export const BarChart = ({ 
  data: chartData, 
  config,
  variant = {
    orientation: 'vertical', // 'vertical' or 'horizontal'
    stacked: false // true or false
  }
}) => {
  const { classes } = useStyles();
  const [showTooltip] = useState(false);
  const [tooltipPosition, settooltipPosition] = useState({
    active: false,
  });
  const { handleDrilldown } = useReport();
  
  // Use the time grouping hook
  const { timeGrouping } = useTimeGrouping(chartData);
  
  const { series, showLegend } = useSeries(config);

  // Use the chart formatting hook with the extracted timeGrouping
  const { formatXAxisTick, CustomTooltip, getCustomXAxisProps } = useChartFormatting(timeGrouping);

  const transformedData = useTransformData(chartData);

  // Handle click events on chart elements
  const handleElementClick = useCallback((entry, index) => {
    if (config?.drilldown) {
      const drilldownData = {
        ...entry,
        value: entry[index] || entry.count,
        currentDrilldownLabel: config?.drilldown?.currentDrilldownLabel
      };
      handleDrilldown(drilldownData);
    }
  }, [config, handleDrilldown]);

  // Determine if horizontal or vertical orientation
  const isHorizontal = variant.orientation === 'horizontal';

  // Set up axis configurations based on orientation
  const xAxisConfig = isHorizontal ? {
    type: "number",
    domain: [0, (dataMax) => Math.round(dataMax) + 2],
    label: { 
      value: series[0]?.axisLabel || "Count", 
      position: 'insideBottom',
      offset: -30
    }
  } : {
    dataKey: "label",
    padding: { left: 30, right: 30 },
    margin: { top: 10 },
    dy: 8,
    angle: timeGrouping === 'Daily' ? 0 : -45,
    textAnchor: timeGrouping === 'Daily' ? "middle" : "end",
    height: timeGrouping === 'Daily' ? 80 : 60,
    tickFormatter: formatXAxisTick,
    interval: 0,
    type: "category",
    ...getCustomXAxisProps()
  };

  const yAxisConfig = isHorizontal ? {
    dataKey: "label",
    type: "category",
    width: 150,
  } : {
    orientation: "left",
    label: { 
      value: series[0]?.axisLabel || "Count", 
      angle: -90, 
      dx: -30 
    },
    dx: -5,
    type: "number",
    domain: [0, (dataMax) => Math.round(dataMax) + 2]
  };

  return (
    <>
      <div style={{ 
        height: "65vh", 
        width: "100%",
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center" 
      }}>
        <div style={{ height: "100%", flexGrow: 1 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={transformedData}
              width={500}
              height={300}
              margin={{
                top: 10,
                right: 30,
                left: isHorizontal ? 150 : 40,
                bottom: 100,
              }}
              barCategoryGap={20}
              barGap={0}
              layout={isHorizontal ? 'vertical' : 'horizontal'}
            >
              <CartesianGrid vertical={!isHorizontal} horizontal={isHorizontal} />
              <XAxis {...xAxisConfig} />
              <YAxis {...yAxisConfig} />
              {tooltipPosition?.active ? (
                <Tooltip
                  content={<CustomTooltip showToolTip={tooltipPosition} legendItems={series} />}
                  formatter={(value, name, props) => [value, name]}
                  wrapperStyle={{
                    opacity: tooltipPosition?.active ? 1 : 0,
                  }}
                />
              ) : (
                <Tooltip
                  content={<CustomTooltip showToolTip={showTooltip} legendItems={series} />}
                  position={tooltipPosition}
                  cursor={{ fill: "transparent" }}
                />
              )}
              {series.map((item, seriesIndex) => (
                <Bar
                  key={item.key}
                  dataKey={item.key}
                  fill={item.getColorByIndex(seriesIndex)}
                  name={item.label}
                  isAnimationActive={false}
                  barSize={45}
                  stackId={variant.stacked ? "stack" : null}
                  onMouseOver={(e) => {
                    settooltipPosition((prev) => ({
                      active: true,
                    }));
                  }}
                  onMouseLeave={(e) => {
                    settooltipPosition((prev) => ({
                      active: false,
                    }));
                  }}
                  onClick={(data, index) => {
                    if (config?.drilldown && item.drilldown !== false) {
                      handleElementClick(data.payload, item.key);
                    }
                  }}
                  cursor={config?.drilldown ? 'pointer' : null}
                  className={config?.drilldown ? classes.clickableElement : ''}
                 >
                   {transformedData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={item.getColorByIndex(index)}
                      />
                    ))}
                </Bar>
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        {showLegend() && <Legend items={series} chartType="bar" position="right" />}
      </div>
    </>
  );
};

// For backward compatibility
export const VerticalBarChart = (props) => {
  return <BarChart {...props} variant={{ orientation: 'vertical', stacked: false }} />;
};

// Additional specific bar chart variants
export const HorizontalBarChart = (props) => {
  return <BarChart {...props} variant={{ orientation: 'horizontal', stacked: false }} />;
};

export const StackedBarChart = (props) => {
  return <BarChart {...props} variant={{ orientation: 'vertical', stacked: true }} />;
};

export const HorizontalStackedBarChart = (props) => {
  return <BarChart {...props} variant={{ orientation: 'horizontal', stacked: true }} />;
}; 