import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress,
} from "@mui/material";
import ColourConstants from "helpers/colourConstants";

const useStyles = makeStyles()((theme) => ({
  dialogContent: {
    width: 500,
    "@media (max-width: 414px)": {
      width: "100%",
    },
  },
  actionContainer: {
    padding: "15px 24px",
    backgroundColor: "#F3F3F3",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
  },
  confirmButton: {
    height: 43,
    fontSize: 15,
    backgroundColor: ColourConstants.confirmButton,
    color: "#FFFFFF",
    minWidth: "120px",
    fontFamily: "Roboto Condensed",
    "&:hover": {
      backgroundColor: ColourConstants.confirmButtonHover,
    },
  },
  cancelButton: {
    height: 43,
    fontSize: 15,
    backgroundColor: ColourConstants.cancelButton,
    color: "#FFFFFF", 
    minWidth: "120px",
    fontFamily: "Roboto Condensed",
    "&:hover": {
      backgroundColor: ColourConstants.cancelButtonHover,
    },
  },
}));

const ConfirmDialog = ({
  open,
  title,
  message,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel", 
  onConfirm,
  onCancel,
}) => {
  const { classes } = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleConfirm = async () => {
    setIsProcessing(true);
    try {
      await onConfirm();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      {isProcessing && <LinearProgress />}
      
      <DialogTitle>{title}</DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>

      <DialogActions className={classes.actionContainer}>
        <div className={classes.buttonContainer}>
          <Button
            onClick={onCancel}
            className={classes.cancelButton}
            disabled={isProcessing}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={handleConfirm}
            className={classes.confirmButton}
            disabled={isProcessing}
          >
            {confirmButtonText}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog; 