import { useState, useEffect } from 'react';
import { getModelVersionIntervals } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../../redux/common/actions';

export const useIntervals = (modelVersionId, roleId, showAll = true) => {
  const dispatch = useDispatch();
  const [intervals, setIntervals] = useState([]);

  useEffect(() => {
    const loadIntervals = async () => {
      if (!modelVersionId) {
        setIntervals(showAll ? [{ id: '', name: 'Show All' }] : []);
        return;
      }

      try {
        const response = await getModelVersionIntervals({ 
          modelVersionId,
          roleId: roleId || ''
        });
        
        if (response?.status) {
          setIntervals([
            ...(showAll ? [{ id: '', name: 'Show All' }] : []),
            ...response.data
          ]);
        }
      } catch (error) {
        dispatch(showError('Failed to load intervals'));
      }
    };
    
    loadIntervals();
  }, [modelVersionId, roleId, showAll]);

  return intervals;
}; 