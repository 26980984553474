import React from 'react';
import { makeStyles } from 'tss-react/mui';
import NavDetails from "components/Elements/NavDetails";
import { analyticsPath, appPath, experimentalPath, newAnalyticsPath } from "helpers/routePaths";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BugReportIcon from '@mui/icons-material/BugReport';
import BuildIcon from '@mui/icons-material/Build';
import MonitorIcon from '@mui/icons-material/Monitor';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link, Routes, Route } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  CompletedVsOutstandingReport,
  DefectsTypeReport,
  DefectsRiskRatingReport,
  DefectsSystemReport,
  DefectsRegisteredReport,
  DefectsDepartmentReport,
  ServicePauseReport,
  ServiceSkippedReport,
  ServiceStatusReport,
  ServiceStopReport,
  ServiceAverageTimesReport,
  MissingPartsToolsReport,
  ConditionMonitoringReport,
  OverdueServicesReport,
  PlannedWorkComplianceReport
} from './Reports';
import DefectsStatusReport from './Reports/DefectsStatusReport';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: '0',
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: 500,
    marginBottom: '8px',
    color: '#000',
  },
  sectionSubtitle: {
    fontSize: '20px',
    fontWeight: 500,
    marginTop: '32px',
    marginBottom: '8px',
    color: '#000',
  },
  sectionDescription: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    marginBottom: '24px',
  },
  analyticsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '24px',
    marginBottom: '24px',
  },
  analyticsLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
    padding: '20px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      '& $title': {
        color: 'rgb(48,122,215)',
      }
    }
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '4px',
  },
  description: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    marginLeft: '28px',
  },
  icon: {
    color: 'rgb(48,122,215)',
    fontSize: '20px',
  }
}));

const AnalyticsDashboard = () => {
  const { classes } = useStyles();
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));

  const defectAnalysisLinks = [
    {
      title: `${customCaptions?.defectPlural} by ${customCaptions?.type || 'Type'}`,
      description: `Analyze ${customCaptions?.defectPlural?.toLowerCase()} categorized by their classification`,
      icon: <BugReportIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/defects-by-type`
    },
    {
      title: `${customCaptions?.defectPlural} by Department`,
      description: `View ${customCaptions?.defectPlural?.toLowerCase()} distribution across departments`,
      icon: <AssessmentIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/defects-by-department`
    },
    {
      title: `${customCaptions?.defectPlural} by ${customCaptions?.riskRating}`,
      description: `View ${customCaptions?.defectPlural?.toLowerCase()} grouped by severity and risk level`,
      icon: <AssessmentIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/defects-risk-rating`
    },
    {
      title: `${customCaptions?.defectPlural} by ${customCaptions?.system || 'System'}`,
      description: `Monitor ${customCaptions?.defectPlural?.toLowerCase()} across different system components`,
      icon: <ShowChartIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/defects-system`
    },
    {
      title: `${customCaptions?.defectPlural} Registered`,
      description: `Track newly registered ${customCaptions?.defectPlural?.toLowerCase()} over time`,
      icon: <ShowChartIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/defects-registered`
    },
    {
      title: `${customCaptions?.defectPlural} by Status`,
      description: `View ${customCaptions?.defectPlural?.toLowerCase()} distribution by their current status`,
      icon: <AssessmentIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/defects-by-status`
    },
  ];

  const serviceManagementLinks = [
    {
      title: `${customCaptions?.service} Pause Reasons`,
      description: 'Analyze causes of service interruptions',
      icon: <PauseCircleOutlineIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/service-pause`
    },
    {
      title: `${customCaptions?.service} Stop Reasons`,
      description: 'Track reasons for service stoppages',
      icon: <StopCircleIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/service-stop`
    },
    {
      title: `${customCaptions?.service} Status Change`,
      description: 'Monitor service status transitions',
      icon: <ChangeCircleIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/service-status`
    },
    {
      title: `${customCaptions?.service} Average Times`,
      description: 'Measure average service duration metrics',
      icon: <AccessTimeIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/service-average-times`
    },
    {
      title: `${customCaptions?.service} Skipped ${customCaptions?.task} Reasons`,
      description: 'Track skipped tasks and their reasons',
      icon: <StopCircleIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/service-skipped`
    },
    {
      title: `Overdue ${customCaptions?.servicePlural}`,
      description: `Track and analyze overdue ${customCaptions?.servicePlural?.toLowerCase()}`,
      icon: <AccessTimeIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/overdue-services`
    },
    {
      title: 'Planned Work Compliance',
      description: 'Track and analyze planned work compliance',
      icon: <AssessmentIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/planned-work-compliance`
    },
  ];

  const performanceMetricsLinks = [
    {
      title: 'Condition Monitoring',
      description: 'Monitor system health and conditions',
      icon: <MonitorIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/condition-monitoring`
    },
    {
      title: `Completed vs Outstanding ${customCaptions?.defectPlural}`,
      description: `Compare resolved and pending ${customCaptions?.defectPlural?.toLowerCase()}`,
      icon: <ShowChartIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/completed-vs-outstanding`
    },
    {
      title: `Missing ${customCaptions?.toolPlural || 'Tools'} by Reason`,
      description: 'Track inventory and tool availability',
      icon: <BuildIcon className={classes.icon} />,
      path: `${appPath}${newAnalyticsPath}/missing-parts-tools`
    }
  ];

  const AnalyticsLink = ({ title, description, icon, path }) => (
    <Link to={path} className={classes.analyticsLink}>
      <Typography className={classes.title}>
        {icon}
        {title}
      </Typography>
      <Typography className={classes.description}>
        {description}
      </Typography>
    </Link>
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className={classes.container}>
            <NavDetails
              history={false}
              status={false}
              staticCrumbs={[]}
            />
            <Typography className={classes.sectionTitle}>
              Analytics Dashboard
            </Typography>
            <Typography className={classes.sectionDescription}>
              Comprehensive analytics and reporting tools to monitor system performance and track {customCaptions?.defectPlural?.toLowerCase()}
            </Typography>

            <Typography className={classes.sectionSubtitle}>
              {customCaptions?.defect} Analysis
            </Typography>
            <Typography className={classes.sectionDescription}>
              Track and analyze {customCaptions?.defectPlural?.toLowerCase()} across different dimensions
            </Typography>
            <div className={classes.analyticsGrid}>
              {defectAnalysisLinks.map((link, index) => (
                <AnalyticsLink key={index} {...link} />
              ))}
            </div>

            <Typography className={classes.sectionSubtitle}>
              {customCaptions?.service} Management
            </Typography>
            <Typography className={classes.sectionDescription}>
              Monitor {customCaptions?.service?.toLowerCase()} operations and performance
            </Typography>
            <div className={classes.analyticsGrid}>
              {serviceManagementLinks.map((link, index) => (
                <AnalyticsLink key={index} {...link} />
              ))}
            </div>

            <Typography className={classes.sectionSubtitle}>
              Performance Metrics
            </Typography>
            <Typography className={classes.sectionDescription}>
              Track key performance indicators and trends
            </Typography>
            <div className={classes.analyticsGrid}>
              {performanceMetricsLinks.map((link, index) => (
                <AnalyticsLink key={index} {...link} />
              ))}
            </div>
          </div>
        }
      />
      <Route 
        path="completed-vs-outstanding" 
        element={<CompletedVsOutstandingReport />} 
      />
      <Route path="defects-by-type" element={<DefectsTypeReport />} />
      <Route path="defects-by-department" element={<DefectsDepartmentReport />} />
      <Route path="defects-risk-rating" element={<DefectsRiskRatingReport />} />
      <Route path="defects-system" element={<DefectsSystemReport />} />
      <Route path="defects-registered" element={<DefectsRegisteredReport />} />
      <Route path="service-pause" element={<ServicePauseReport />} />
      <Route path="service-skipped" element={<ServiceSkippedReport />} />
      <Route path="service-status" element={<ServiceStatusReport />} />
      <Route path="service-stop" element={<ServiceStopReport />} />
      <Route path="service-average-times" element={<ServiceAverageTimesReport />} />
      <Route path="missing-parts-tools" element={<MissingPartsToolsReport />} />
      <Route path="condition-monitoring" element={<ConditionMonitoringReport />} />
      <Route path="overdue-services" element={<OverdueServicesReport />} />
      <Route path="planned-work-compliance" element={<PlannedWorkComplianceReport />} />
      <Route path="defects-by-status" element={<DefectsStatusReport />} />
    </Routes>
  );
};

export default AnalyticsDashboard;