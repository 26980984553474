import React from "react";
import { Typography, Box } from '@mui/material';
import { makeStyles } from "tss-react/mui";
import ColourConstants from "helpers/colourConstants";
import { useFilters } from "../Filters/FilterContext";
import { changeDate } from "helpers/date";

const useStyles = makeStyles()((theme) => ({
  header: {
    color: ColourConstants.commonText,
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 4,
    lineHeight: 1.2
  },
  subHeader: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    marginBottom: 2
  },
  dateRange: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    display: 'flex',
    alignItems: 'center',
    marginTop: 2
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const ChartTitle = ({
  datas = [],
  title,
  subTitle,
  afterClick,
  isConditionMonitor = false,
  conditionMonitorData,
}) => {
  const { classes } = useStyles();
  const { filterValues } = useFilters();
  
  // Get timeframe data directly from filters
  const timeframe = filterValues?.timeframe?.value;
  const startDateTime = timeframe?.fromDate ? changeDate(timeframe.fromDate) : null;
  const endDateTime = timeframe?.toDate ? changeDate(timeframe.toDate) : null;
  const isShowAll = timeframe?.name === "Show All";
  
  // Determine if we should show the date range (when timeframe is not "Show All")
  const showDateRange = timeframe && !isShowAll && startDateTime && endDateTime;
  
  // Get timezone information
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneAbbr = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];

  return (
    <div className={classes.container}>
      <Typography className={classes.header}>{title}</Typography>
      {subTitle && (
        <Typography className={classes.subHeader}>{subTitle}</Typography>
      )}
      {showDateRange && (
        <Typography className={classes.dateRange} sx={{ fontWeight: 500 }}>
          {`${startDateTime} - ${endDateTime}`}
          <Box component="span" sx={{ marginLeft: 1, fontWeight: 400 }}>
            ({timezoneAbbr || timezone})
          </Box>
        </Typography>
      )}
      {isConditionMonitor && (
        <Typography className={classes.subHeader}>
          {conditionMonitorData}
        </Typography>
      )}
    </div>
  );
}; 