import { useMemo } from 'react';

/**
 * Hook to extract and memoize time grouping information from chart data
 * @param {Object} chartData - The chart data object with multiple series
 * @returns {Object} Time grouping information and utility functions
 */
const useTimeGrouping = (chartData) => {
  return useMemo(() => {
    if (!chartData || Object.keys(chartData).length === 0) {
      return {
        timeGrouping: 'Monthly', // Default time grouping
        getTimeGroupingFromData: () => 'Monthly'
      };
    }

    // Extract time grouping from the first item in the first series
    const seriesKeys = Object.keys(chartData);
    let timeGrouping = 'Monthly'; // Default

    if (seriesKeys.length > 0 && chartData[seriesKeys[0]]?.length > 0) {
      const firstItem = chartData[seriesKeys[0]][0];
      
      // Determine time grouping based on properties present in the data
      if (firstItem.Hour !== undefined) {
        timeGrouping = 'Hourly';
      } else if (firstItem.Day !== undefined) {
        timeGrouping = 'Daily';
      } else if (firstItem.Week !== undefined) {
        timeGrouping = 'Weekly';
      } else if (firstItem.Year !== undefined && firstItem.Month !== undefined) {
        timeGrouping = 'Monthly';
      }
    }

    /**
     * Function to get time grouping from data
     * @returns {string} The time grouping ('Hourly', 'Daily', 'Weekly', 'Monthly')
     */
    const getTimeGroupingFromData = () => {
      return timeGrouping;
    };

    return {
      timeGrouping,
      getTimeGroupingFromData,
    };
  }, [chartData]);
};

export default useTimeGrouping; 