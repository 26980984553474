import React from 'react';
import { Button, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFilters } from '../Filters/FilterContext';
import { useReport } from '../ReportContext';

export const ViewDataButton = ({ data, config }) => {
  // Get filter values from context
  const { filterValues } = useFilters();
  // Get drilldown history from ReportContext
  const { drilldownHistory } = useReport();

  // Function to handle view data button click
  const handleViewData = () => {
    const viewDataUrl = typeof config.viewData === 'function' 
      ? config.viewData(data) 
      : config.viewData;
    
    // Transform filterValues to keep only the 'value' part for each key
    const simplifiedFilters = {};
    Object.keys(filterValues).forEach(key => {
      simplifiedFilters[key] = filterValues[key].value;
    });

    // Add parameters from drilldown history
    drilldownHistory.forEach(historyItem => {
      if (historyItem.params) {
        Object.assign(simplifiedFilters, historyItem.params);
      }
    });
    
    // NOTE! Legacy handling before we put params in everywhere
    // Ensure all expected keys are present with default values if missing
    const expectedKeys = [
      'department', 
      'timeframe', 
      'model', 
      'riskRatings', 
      'defectType', 
      'defectStatus', 
      'defectWorkOrder', 
      'defectSystem'
    ];
    
    expectedKeys.forEach(key => {
      if (!simplifiedFilters[key]) {
        // Add default empty value for missing keys
        simplifiedFilters[key] = {
          id: "",
          name: "Show All"
        };
      }
    });
        
    // Store data and simplified filter values in localStorage
    localStorage.setItem(
      "analyticaData",
      JSON.stringify({ 
        data: simplifiedFilters, 
        isRedirected: true
      })
    );
    
    if (viewDataUrl) {
      window.open(viewDataUrl, '_blank');
    }
  };

  // Only render if viewData is configured
  if (!config.viewData) {
    return null;
  }

  return (
    <Tooltip title="View Data">
      <Button
        variant="contained"
        size="small"
        onClick={handleViewData}
        startIcon={<VisibilityIcon fontSize="small" />}
        sx={{
          height: '32px',
          backgroundColor: 'rgb(48,122,215)',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'rgb(38,97,171)',
          }
        }}
      >
        View Data
      </Button>
    </Tooltip>
  );
}; 