import { useMemo } from 'react';
import useChartColors from './useChartColors';

/**
 * Custom hook for managing series configuration with enhanced color management
 * 
 * @param {Object} chartConfig - The chart configuration object containing series (array or function)
 * @returns {Object} Object containing enhanced series array with color management functions
 */
const useSeries = (chartConfig) => {
  const chartColors = useChartColors();
  
  const enhancedSeries = useMemo(() => {
    // Handle different types of chartConfig.series
    let seriesData = [];
    
    // If no config at all
    if (!chartConfig?.series) {
      return [];
    }
    
    // Handle function case
    if (typeof chartConfig.series === 'function') {
      // Note: This is a synchronous approach and won't work with async functions
      try {
        const result = chartConfig.series();
        seriesData = Array.isArray(result) ? result : [];
      } catch (error) {
        console.error('Error calling series function:', error);
        return [];
      }
    } 
    // Handle array case
    else if (Array.isArray(chartConfig.series)) {
      seriesData = chartConfig.series;
    } 
    // Handle invalid case
    else {
      return [];
    }
    
    // If series data is empty, return empty array
    if (!seriesData.length) {
      return [];
    }
    
    return seriesData.map((series, seriesIndex) => {
      // Create a function to get color by index for this specific series
      const getColorByIndex = (index, category) => {
        // If series has predefined colors, use those
        if (series.colors && Array.isArray(series.colors) && series.colors.length > 0) {
          return series.colors[index % series.colors.length];
        }
        
        // Otherwise use the category from series or default
        const colorCategory = category || series.colorCategory || 'primary';
        return chartColors.getColorByIndex(index, colorCategory);
      };
      
      // Create a function to generate multiple colors for this series
      const generateColors = (count, category) => {
        // If series has predefined colors, use those (with repetition if needed)
        if (series.colors && Array.isArray(series.colors) && series.colors.length > 0) {
          return Array.from({ length: count }, (_, i) => 
            series.colors[i % series.colors.length]
          );
        }
        
        // Otherwise use the category from series or default
        const colorCategory = category || series.colorCategory || 'primary';
        return chartColors.generateColors(count, colorCategory);
      };
      
      // Create a function to get all colors for this series
      const getAllColors = (category) => {
        // If series has predefined colors, return those
        if (series.colors && Array.isArray(series.colors) && series.colors.length > 0) {
          return [...series.colors];
        }
        
        // Otherwise return all colors from the specified category
        const colorCategory = category || series.colorCategory || 'primary';
        return chartColors.getColorsByCategory(colorCategory);
      };
      
      // Return enhanced series object with color functions
      return {
        ...series,
        // Add index for reference
        index: seriesIndex,
        // Add color management functions
        getColorByIndex,
        generateColors,
        getAllColors,
        // Add a default color for convenience
        defaultColor: getColorByIndex(0, series.colorCategory)
      };
    });
  }, [chartConfig, chartColors]);
  
  // Return an object with the series property for easier destructuring
  return {
    series: enhancedSeries,
    // Add utility functions that work on all series if needed
    getSeriesById: (id) => enhancedSeries.find(s => s.id === id || s.key === id),
    getSeriesByIndex: (index) => enhancedSeries[index],
    showLegend: () => enhancedSeries.some(series => series.showLegend !== false),
    // We don't have actual loading state in this implementation
    // For async loading, use the useState+useEffect pattern
    isLoading: false
  };
};

export default useSeries; 