import { useState, useEffect } from 'react';
import { getModelVersionRoles } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../../redux/common/actions';

export const useRoles = (modelVersionId, showAll = true) => {
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const loadRoles = async () => {
      if (!modelVersionId) {
        setRoles(showAll ? [{ id: '', name: 'Show All' }] : []);
        return;
      }

      try {
        const response = await getModelVersionRoles({ 
          modelVersionId 
        });
        
        if (response?.status) {
          setRoles([
            ...(showAll ? [{ id: '', name: 'Show All' }] : []),
            ...response.data
          ]);
        }
      } catch (error) {
        dispatch(showError('Failed to load roles'));
      }
    };
    
    loadRoles();
  }, [modelVersionId, showAll]);

  return roles;
}; 