import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { filterByDateOptions } from 'constants/Analytics';
import { useFilters } from './FilterContext';
import CustomDateRange from 'pages/Services/ServiceLists/CustomDateRange';
import { customFromattedDate } from 'helpers/utils';
import { changeDate } from 'helpers/date';
import { getSiteApplicationDetail } from 'services/clients/sites/siteApplications/siteApplicationDetails';

export const TimeframeFilter = () => {
  const { filterValues, updateFilter } = useFilters();
  const [openCustomDatePopup, setOpenCustomDatePopup] = useState(false);
  const [customDate, setCustomDate] = useState({ from: '', to: '' });
  const [isCustomDateRangeError, setCustomDateRangeError] = useState({ from: false, to: false });
  const [weekStartDay, setWeekStartDay] = useState(1); // Default to Monday
  
  // Get weekStartDay from site application details
  useEffect(() => {
    const fetchSiteApplicationDetails = async () => {
      try {
        const { siteAppID } = JSON.parse(localStorage.getItem("me")) || JSON.parse(sessionStorage.getItem("me"));
        const result = await getSiteApplicationDetail(siteAppID);
        setWeekStartDay(result.data.weekStartDay);
      } catch (error) {
        console.error("Error fetching site application details:", error);
      }
    };
    fetchSiteApplicationDetails();
  }, []);

  // Create timeframe options with current weekStartDay
  const timeframeOptions = React.useMemo(() => {
    return filterByDateOptions(new Date(), null, weekStartDay);
  }, [weekStartDay]);

  // Set default timeframe on mount or when weekStartDay changes
  useEffect(() => {
    if (!filterValues.timeframe) {
      updateFilter('timeframe', timeframeOptions[0], {
        startDate: timeframeOptions[0].fromDate,
        endDate: timeframeOptions[0].toDate
      }); // Default to Show All
    }
  }, [weekStartDay]); // Add weekStartDay as dependency

  // Don't render until we have a timeframe value
  if (!filterValues.timeframe) return null;

  const handleCustomDateChange = (type, e) => {
    setCustomDate({ ...customDate, [type]: e.target.value });
  };

  const handleCloseCustomDate = () => {
    setOpenCustomDatePopup(false);
    setCustomDateRangeError({ from: false, to: false });
  };

  const handleCustomDateSubmit = (e) => {
    e.preventDefault();
    const isFromDateEmpty = customDate.from === '';
    const isToDateEmpty = customDate.to === '';
    
    if (isFromDateEmpty || isToDateEmpty) {
      setCustomDateRangeError({ from: isFromDateEmpty, to: isToDateEmpty });
      return;
    }

    const formattedCustomDate = customFromattedDate(customDate);
    const customTimeframe = {
      ...filterValues.timeframe,
      ...formattedCustomDate,
      name: 'Customized Date',
      id: 5
    };

    updateFilter('timeframe', customTimeframe, {
      startDate: formattedCustomDate.fromDate,
      endDate: formattedCustomDate.toDate
    });

    handleCloseCustomDate();
  };

  const selectedValue = filterValues.timeframe?.value;

  return (
    <>
      <Grid item lg={3} md={6} xs={12}>
        <DyanamicDropdown
          dataSource={timeframeOptions}
          columns={[{ name: 'name', id: 1, minWidth: '130px' }]}
          showHeader={false}
          width="100%"
          placeholder="Select Timeframe"
          onChange={item => {
            if (item.id === 5) { // Custom range
              setOpenCustomDatePopup(true);
              return;
            }
            updateFilter('timeframe', item, {
              startDate: item.fromDate || '',
              endDate: item.toDate || ''
            });
          }}
          selectdValueToshow="name"
          selectedValue={{
            ...selectedValue,
            name: selectedValue?.id === 5 && selectedValue?.fromDate
              ? `${changeDate(selectedValue?.fromDate)} - ${changeDate(selectedValue?.toDate)}`
              : selectedValue?.name
          }}
          label="Filter by Timeframe"
          icon={<FilterListIcon style={{ color: 'rgb(48,122,215)' }} />}
          required={false}
          showBorderColor
          showSearchBox={false}
        />
      </Grid>
      
      <CustomDateRange
        open={openCustomDatePopup}
        handleChange={handleCustomDateChange}
        customDate={customDate}
        closeHandler={handleCloseCustomDate}
        onSubmit={handleCustomDateSubmit}
        isError={isCustomDateRangeError}
        isLoading={false}
      />
    </>
  );
}; 
