import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultRedirect } from "helpers/constants";

const ProtectedLogin = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const me =
			JSON.parse(sessionStorage.getItem("me")) ||
			JSON.parse(localStorage.getItem("me"));

		// Check for both feedback and analytics URLs with siteAppId
		const isNavigatingFromSpecialPage =
			location.search.includes("siteAppId") && 
			(location.pathname.includes("feedback") || 
			 location.pathname.includes("/analytics/"));

		if (me && !isNavigatingFromSpecialPage) {
			const state = location.state || {};
			navigate(
				state?.from?.pathname
					? state.from.pathname
					: me.siteAppID
					? defaultRedirect[me.position.defaultPage]
					: "/app/me",
				{ state: { from: location } }
			);
		}
	}, [location, navigate]);

	return children;
};

export default ProtectedLogin;
