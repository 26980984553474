/**
 * Generates a subtitle string based on the current filter values
 * @param {Object} filters - The filter values object
 * @returns {string} A formatted subtitle string
 */
export const getFilterSubtitle = (filters) => {
  const parts = [];

  // Loop through all available filters
  Object.entries(filters).forEach(([filterType, filterData]) => {
    if (filterData?.value) {
      // Handle timeframe filter
      if (filterType === 'timeframe' && filterData.value.name) {
        parts.push(filterData.value.name);
      } 
      // Handle other filters with id/name pattern
      else if (filterData.value.id !== undefined) {
        const filterName = filterType.charAt(0).toUpperCase() + filterType.slice(1); // Capitalize filter name
        parts.push(filterData.value.id === '' ? `All ${filterName}s` : filterData.value.name);
      }
    }
  });

  return parts.length > 0 ? parts.join(' - ') : 'Show All';
}; 