import React, { useState, useCallback } from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { makeStyles } from "tss-react/mui";
import { Legend } from '../Layout';
import { useReport } from '../ReportContext';
import { useChartFormatting, useTimeGrouping, useTransformData, useSeries } from '../hooks';

const useStyles = makeStyles()((theme) => ({
  customTooltip: {
    background: "#e9e9e9",
    padding: "6px 12px",
    border: "1px solid",
    borderRadius: "3px",
  },
  toolTipLabel: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "& p": {
      margin: "0px !important",
    },
  },
  colorBox: {
    width: "14px",
    height: "14px",
  },
  label: {
    margin: 0,
  },
  clickableElement: {
    cursor: 'pointer',
  }
}));

export const MultiBarLineChart = ({ 
  data: chartData, 
  config,
  isDrilledDown
}) => {
  const { classes } = useStyles();
  const [showTooltip] = useState(false);
  const [tooltipPosition, settooltipPosition] = useState({
    active: false,
  });
  const { handleDrilldown } = useReport();
  
  // Use the time grouping hook
  const { timeGrouping } = useTimeGrouping(chartData);
  
  const { series, showLegend } = useSeries(config);

  // Use the chart formatting hook with the extracted timeGrouping
  const { formatXAxisTick, CustomTooltip, getCustomXAxisProps } = useChartFormatting(timeGrouping);

  const transformedData = useTransformData(chartData);

  // Handle click events on chart elements
  const handleElementClick = useCallback((entry, key) => {
    if (config?.drilldown) {
      const drilldownData = {
        ...entry,
        value: entry[key],
        currentDrilldownLabel: config?.drilldown?.currentDrilldownLabel
      };
      handleDrilldown(drilldownData);
    }
  }, [config, handleDrilldown]);

  // Set up axis configurations
  const xAxisConfig = {
    dataKey: "label",
    xAxisId: "xAxis",
    padding: { left: 30, right: 30 },
    margin: { top: 10 },
    dy: 8,
    angle: timeGrouping === 'Daily' ? 0 : -45,
    textAnchor: timeGrouping === 'Daily' ? "middle" : "end",
    height: timeGrouping === 'Daily' ? 80 : 60,
    tickFormatter: formatXAxisTick,
    interval: 0,
    type: "category",
    ...getCustomXAxisProps()
  };

  // Group series by type
  const barSeries = series.filter(item => item.type === 'bar');
  const lineSeries = series.filter(item => item.type === 'line');

  // Find max values for each axis to set appropriate domains
  const getMaxValue = (data, keys) => {
    if (!data || !data.length) return 0;
    return Math.max(...data.flatMap(item => 
      keys.map(key => item[key] || 0)
    ));
  };

  const barMaxValue = getMaxValue(transformedData, barSeries.map(s => s.key));
  const lineMaxValue = getMaxValue(transformedData, lineSeries.map(s => s.key));

  // Get axis labels from series configuration or use defaults
  const getAxisLabel = (seriesItems, defaultLabel) => {
    if (!seriesItems || seriesItems.length === 0) return defaultLabel;
    // Use the first series item's axisLabel if available, otherwise use its label
    return seriesItems[0].axisLabel || seriesItems[0].label || defaultLabel;
  };

  const leftAxisLabel = getAxisLabel(barSeries, "Count");
  const rightAxisLabel = getAxisLabel(lineSeries, "Average");

  // Primary Y-axis config (for bar series)
  const primaryYAxisConfig = {
    orientation: "left",
    label: { value: leftAxisLabel, angle: -90, dx: -30 },
    dx: -5,
    type: "number",
    domain: [0, (dataMax) => Math.round(barMaxValue) + 2],
    yAxisId: "left"
  };

  // Secondary Y-axis config (for line series)
  const secondaryYAxisConfig = {
    orientation: "right",
    label: { value: rightAxisLabel, angle: 90, dx: 30 },
    dx: 5,
    type: "number",
    domain: [0, (dataMax) => Math.round(lineMaxValue) + 2],
    yAxisId: "right"
  };

  return (
    <>
      <div style={{ 
        height: "65vh", 
        width: "100%",
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center" 
      }}>
        <div style={{ height: "100%", flexGrow: 1 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={transformedData}
              width={500}
              height={300}
              margin={{
                top: 10,
                right: 30,
                left: 40,
                bottom: 50,
              }}
              barCategoryGap={20}
              barGap={0}
            >
              <CartesianGrid vertical={false} />
              <XAxis {...xAxisConfig} />
              <YAxis {...primaryYAxisConfig} />
              {lineSeries.length > 0 && <YAxis {...secondaryYAxisConfig} />}
              {tooltipPosition?.active ? (
                <Tooltip
                  content={<CustomTooltip showToolTip={tooltipPosition} legendItems={series} />}
                  formatter={(value, name, props) => [value, name]}
                  wrapperStyle={{
                    opacity: tooltipPosition?.active ? 1 : 0,
                  }}
                />
              ) : (
                <Tooltip
                  content={<CustomTooltip showToolTip={showTooltip} legendItems={series} />}
                  position={tooltipPosition}
                  cursor={{ fill: "transparent" }}
                />
              )}
              
              {/* Render Bar Series */}
              {barSeries.map((item, seriesIndex) => (
                <Bar
                  key={item.key}
                  dataKey={item.key}
                  xAxisId="xAxis"
                  fill={item.getColorByIndex(seriesIndex)}
                  name={item.label}
                  isAnimationActive={false}
                  barSize={45}
                  yAxisId="left"
                  onMouseOver={(e) => {
                    settooltipPosition((prev) => ({
                      active: true,
                    }));
                  }}
                  onMouseLeave={(e) => {
                    settooltipPosition((prev) => ({
                      active: false,
                    }));
                  }}
                  onClick={(data, index) => {
                    if (config?.drilldown && item.drilldown !== false) {
                      handleElementClick(data.payload, item.key);
                    }
                  }}
                  cursor={config?.drilldown ? 'pointer' : null}
                  className={config?.drilldown ? classes.clickableElement : ''}
                >
                  {transformedData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={item.getColorByIndex(index)}
                    />
                  ))}
                </Bar>
              ))}
              
              {/* Render Line Series */}
              {lineSeries.map((item, seriesIndex) => (
                <Line
                  key={item.key}
                  xAxisId="xAxis"
                  type="linear"
                  dot={{ 
                    strokeWidth: 2, 
                    r: 6, 
                    fill: item.getColorByIndex(seriesIndex),
                    className: config?.drilldown ? classes.clickableElement : ''
                  }}
                  data={transformedData}
                  dataKey={item.key}
                  stroke={item.getColorByIndex(seriesIndex)}
                  isAnimationActive={false}
                  yAxisId="right"
                  activeDot={{
                    onMouseOver: (e, i) => {
                      settooltipPosition((prev) => ({
                        active: true,
                      }));
                    },
                    onMouseLeave: (e) => {
                      settooltipPosition((prev) => ({
                        active: false,
                      }));
                    },
                    onClick: (e, i) => {
                      if (config?.drilldown && item.drilldown !== false) {
                        handleElementClick(i.payload, item.key);
                      }
                    },
                    strokeWidth: 40,
                    stroke: "transparent",
                    cursor: config?.drilldown ? "pointer" : "default",
                    r: 6,
                  }}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        {showLegend() && <Legend items={series} chartType="multi-bar-line" position="right" />}
      </div>
    </>
  );
}; 