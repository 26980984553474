import React, { useState, useEffect } from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { useChartColors } from '../ReportChart/hooks';
import { getAPIResponse } from 'helpers/getApiResponse';
import { Apis } from 'services/api';
import API from "helpers/api";
import { getDefectStatuses } from "services/clients/sites/siteApplications/defectStatuses";
import { getSiteApplicationDetail } from 'services/clients/sites/siteApplications/siteApplicationDetails';

const getCompletedVsOutstanding = async (params) => {
  try {
    let response = await API.get(`${Apis.Analytics}/completedvsoutstandingmodified`, { params });
    return getAPIResponse(response);
  } catch (err) {
    return getAPIResponse(err?.response);
  }
};

const CompletedVsOutstandingReport = () => {
  const { customCaptions, siteAppID } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  const chartColors = useChartColors();
  const [defectStatuses, setDefectStatuses] = useState([]);
  const [siteAppDetail, setSiteAppDetail] = useState(null);

  useEffect(() => {
    const fetchSiteApplicationDetails = async () => {
      try {
        const result = await getSiteApplicationDetail(siteAppID);
        setSiteAppDetail(result.data);
      } catch (error) {
        console.error("Error fetching site application details:", error);
      }
    };
    fetchSiteApplicationDetails();
  }, [siteAppID]);

  useEffect(() => {
    const fetchDefectStatuses = async () => {
      try {
        const statuses = await getDefectStatuses();
        if (statuses?.data) {
          setDefectStatuses(statuses.data);
        }
      } catch (error) {
        console.error("Error fetching defect statuses:", error);
      }
    };

    fetchDefectStatuses();
  }, []);

  const chartConfig = {
    id: 'completed-vs-outstanding',
    title: `${customCaptions?.defectPlural} by Status`,
    fetchData: async (params) => {
      return await getCompletedVsOutstanding(params);
    },
    allowedChartTypes: ['line', 'vertical-bar', 'stacked-bar', 'horizontal-stacked-bar'],
    defaultChartType: 'line',
    series: () => {
      if (!defectStatuses.length) {
        return [];
      }

      // Generate dynamic series based on defect statuses
      return defectStatuses.map((status, index) => {
        // Determine color type based on rules:
        // 1. Series of type 'C' are positive
        // 2. Series where ID matches the defaultDefectStatusId are negative
        // 3. All others are neutral
        let colorType = 'neutral';
        if (status.type === 'C') {
          colorType = 'positive';
        } else if (status.id === siteAppDetail?.defaultDefectStatusID) {
          colorType = 'negative';
        }
        
        // Get color from appropriate palette
        const color = chartColors.getColorByIndex(index % 5, colorType);

        return {
          label: status.name,
          key: status.name, // Using name as the key
          showLegend: true,
          colors: [color],
          type: status.type, // Add type for reference
        };
      });
    },
    transformData: (response) => {
      if (!response?.data || !Array.isArray(response.data)) {
        return [];
      }

      // Create a map to consolidate data by date
      const dateMap = new Map();

      // Process each status and its data
      response.data.forEach(item => {
        if (item.statusName && Array.isArray(item.data)) {
          item.data.forEach(point => {
            const existingPoint = dateMap.get(point.label) || {
              label: point.label,
              year: point.year,
              month: point.month
            };
            
            // Store the count under the status name key
            existingPoint[item.statusName] = point.count;
            
            dateMap.set(point.label, existingPoint);
          });
        }
      });

      // Convert map to array and sort by date
      return Array.from(dateMap.values())
        .sort((a, b) => {
          if (a.year !== b.year) {
            return a.year - b.year;
          }
          return a.month - b.month;
        });
    },
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: true,
      riskRatings: true
    },
    drilldown: {
      defaultChartType: 'horizontal-bar',
      allowedChartTypes: ['horizontal-bar', 'pie'],
      series: [
        {
          label: `${customCaptions?.asset || 'Asset'} Count`,
          key: 'count',
          legend: false
        }
      ],
      currentDrilldownLabel: `${customCaptions?.asset || 'Asset'} Count`,
      getParams: (dataPoint) => {
        return {
          defectStatusType: dataPoint.originalData?.type || ''
        };
      }
    },
    viewData: '/app/defects'
  };
  return (
    <ReportPage
      title={`Completed vs Outstanding ${customCaptions?.defectPlural}`}
      chartConfig={chartConfig}
    />
  );
};

export default CompletedVsOutstandingReport; 