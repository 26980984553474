import { useState, useEffect } from 'react';
import { getModelsPublished } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../../../redux/common/actions';

export const useModels = (departmentId, showAll = true) => {
  const dispatch = useDispatch();
  const [models, setModels] = useState([]);

  useEffect(() => {
    const loadModels = async () => {
      try {
        const response = await getModelsPublished({
          siteDepartmentId: departmentId
        });
        if (response?.status) {
          setModels([
            ...(showAll ? [{id: '', name: 'Show All'}] : []),
            ...response.data.map(m => ({
              ...m,
              name: `${m.name} ${m.modelName || ''}`
            }))
          ]);
        }
      } catch (error) {
        dispatch(showError('Failed to load models'));
      }
    };
    loadModels();
  }, [departmentId]);

  return models;
}; 