import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { useFilters } from './FilterContext';
import { useModels } from './hooks/useModels';

export const ModelFilter = () => {
  const { filterValues, updateFilter, isRequired, isShowAll } = useFilters();
  const departmentId = filterValues.department?.value?.id;
  
  const models = useModels(departmentId, isShowAll('model'));

  // Reset model when department changes
  useEffect(() => {
    function defaultValue() {
      return isShowAll('model') ? { id: '', name: 'Show All' } : {};
    }

    updateFilter('model', defaultValue(), { modelId: '', activeModelVersionID: '' });
  }, [filterValues.department]);

  return (
    <Grid item lg={3} md={6} xs={12}>
      <DyanamicDropdown
        dataSource={models}
        columns={[{ name: 'name', id: 1 }]}
        showHeader={false}
        width="100%"
        placeholder="Select Model"
        onChange={item => updateFilter('model', item, { 
          modelId: item.id,
          modelVersionId: item.activeModelVersionID,
          modelTemplateType: item.modelTemplateType
        })}
        selectdValueToshow="name"
        selectedValue={filterValues.model?.value}
        label="Filter by Model"
        icon={<FilterListIcon style={{ color: 'rgb(48,122,215)' }} />}
        required={isRequired('model')}
        showBorderColor
      />
    </Grid>
  );
}; 


