import React from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { statusOptions } from "helpers/constants";
import { getPlannedWorkCompliance } from "services/analytics";
import useChartColors from '../ReportChart/hooks/useChartColors';

const getStatusName = (statusId) => {
  const status = statusOptions.find(s => s.id === statusId);
  return status ? status.name : 'Unknown';
};

const PlannedWorkComplianceReport = () => {
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  const { getColorsByCategory } = useChartColors();

  const chartConfig = {
    id: 'planned-work-compliance',
    title: 'Planned Work Compliance',
    currentDrilldownLabel: () => `${customCaptions.servicesPlural || 'Services'} Status`,
    fetchData: async (params) => {
      return await getPlannedWorkCompliance(params);
    },
    allowedChartTypes: ['pie'], // FIXME: EMI3-776
    defaultChartType: 'pie',
    series: [
      { label: 'Work Status', key: 'workStatus', colors: getColorsByCategory('greys'), showLegend: false, drilldown: false },
      { label: 'Status', key: 'statusName', colors: getColorsByCategory('primary'), showLegend: false }
    ],
    transformData: (response) => {
      const data = response?.data?.data || [];
      return data.map(item => ({
        ...item,
        name: item.status ? getStatusName(item.status) : item.status,
        statusCode: item.status
      }));
    },
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: true
    },
    viewData: '/app/services',
    drilldown: {
      allowedChartTypes: ['pie', 'vertical-bar'],
      series: [
        { 
          label: `${customCaptions?.role || 'Role'}`, 
          key: 'count', 
          legend: false
        }
      ],
      transformData: (response) => {
        return (response?.data?.data || []).map(item => ({
          ...item,
          roleId: item.id
        }));
      },
      currentDrilldownLabel: `${customCaptions?.role || 'Role'} Count`,
      getParams: (dataPoint) => {
        return {
          status: dataPoint.statusCode
        };
      },
      drilldown: {
        allowedChartTypes: ['pie', 'vertical-bar'],
        series: [
          { 
            label: `${customCaptions?.model || 'Model'}`, 
            key: 'count', 
            legend: false
          }
        ],
        transformData: (response) => {
          return response?.data?.data || [];
        },
        currentDrilldownLabel: `${customCaptions?.model || 'Model'}`,
        getParams: (dataPoint) => {
          return {
            roleId: dataPoint.roleId
          };
        }
      }
    }
  };

  return (
    <ReportPage 
      title="Planned Work Compliance"
      chartConfig={chartConfig}
    />
  );
};

export default PlannedWorkComplianceReport; 
