import React from 'react';
import { Grid } from '@mui/material';
import { useFilters } from './FilterContext';
import { createFilter } from './FilterFactory';

export const FilterContainer = () => {
  const { config: { filters } } = useFilters();

  // Create array of enabled filter types from config
  const filterTypes = [];
  
  // Add filters based on config
  Object.entries(filters).forEach(([key, enabled]) => {
    if (enabled) {
      filterTypes.push(key);
    }
  });

  return (
    <Grid container spacing={3}>
      {filterTypes.map(filterType => createFilter(filterType))}
    </Grid>
  );
};
