import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DyanamicDropdown from 'components/Elements/DyamicDropdown';
import { useFilters } from './FilterContext';

export const WorkOrderFilter = () => {
  const { filterValues, updateFilter } = useFilters();
  
  // Initialize with default value
  useEffect(() => {
    updateFilter('workOrder', { id: '', name: 'Show All' });
  }, []);

  // Hardcoded work order options (assigned/unassigned)
  const workOrderOptions = [
    { id: '', name: 'Show All' },
    { id: true, name: 'Assigned' },
    { id: false, name: 'Unassigned' },
  ];

  if (!filterValues.workOrder) {
    return null;
  }

  return (
    <Grid item lg={3} md={6} xs={12}>
      <DyanamicDropdown
        dataSource={workOrderOptions}
        columns={[{ name: 'name', id: 1 }]}
        showHeader={false}
        width="100%"
        placeholder="Select Work Order Status"
        onChange={item => updateFilter('workOrder', item, { notificationRaised: item.id })}
        selectdValueToshow="name"
        selectedValue={filterValues.workOrder?.value}
        label="Filter by Work Order Status"
        icon={<FilterListIcon style={{ color: 'rgb(48,122,215)' }} />}
        required={false}
        showBorderColor
      />
    </Grid>
  );
}; 