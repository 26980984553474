import React from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { Apis } from 'services/api';
import API from "helpers/api";
import { getAPIResponse } from 'helpers/getApiResponse';
import { useChartColors } from '../ReportChart/hooks';

const getAverageTimes = async (params) => {
	try {
		let response = await API.get(`${Apis.Analytics}/averagetimes`, { params });
		return getAPIResponse(response);
	} catch (err) {
		return getAPIResponse(err?.response);
	}
};

const ServiceAverageTimesReport = () => {
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  const { getColorsByCategory, getAllColors } = useChartColors();

  const chartConfig = {
    id: 'service-average-times',
    title: `${customCaptions?.service} Average Times`,
    currentDrilldownLabel: () => `${customCaptions.versionPlural || 'Versions'}`,
    fetchData: async (params) => {
      // Return invalid filters status if modelVersionTaskQuestionID is not set
      if (!params.modelId || !params.roleId) {
        return { 
          status: false, 
          invalidFilters: true,
          message: `Please select at least a ${customCaptions?.model || 'Model'} and ${customCaptions?.role || 'Role'}.`
        };
      }
      // Map roleId to modelVersionRoleId becuse that's really what it is
      params['modelVersionRoleId'] = params.roleId;
      // We display all version so strip out the currently version at this level
      params['modelVersionId'] = null;

      return await getAverageTimes(params);
    },
    allowedChartTypes: ['horizontal-bar', 'vertical-bar'],
    defaultChartType: 'vertical-bar',
    series: [
      {
        key: 'estimatedMinutes',
        label: 'Estimated Time (Minutes)',
        axisLabel: 'Minutes',
        colors: [getColorsByCategory('primary')[0]]
      },
      {
        key: 'actualMinutes',
        label: 'Actual Time (Minutes)',
        axisLabel: 'Minutes',
        colors: [getColorsByCategory('primary')[1]]
      }
    ],
    transformData: (response, filterValues) => {
      if (!response?.data?.data) return null;

      const modelInfo = filterValues.model.value;

      return response.data.data.map(item => ({
        id: item.id,
        label: `${modelInfo.name} v${item.version}`,
        estimatedMinutes: parseFloat((item.estimatedMinutes).toFixed(2)),
        actualMinutes: parseFloat((item.actualMinutes).toFixed(2)),
        depth: response.data.depth
      }));
    },
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: {
        required: true,
        showAll: false,
      },
      role: { 
        required: true,
        showAll: false
      },
      asset: { 
        show: model => model?.modelTemplateType === 'A'
      },
      interval: { required: false, showAll: false }
    },
    drilldown: {
      defaultChartType: 'vertical-bar',
      allowedChartTypes: ['vertical-bar', 'horizontal-bar'],
      currentDrilldownLabel: () => `${customCaptions.stagesPlural || 'Stages'}`,
      fetchData: async (params) => {
        if (!params.modelId || !params.roleId) {
          return { 
            status: false, 
            invalidFilters: true,
            message: `Please select at least a ${customCaptions?.model || 'Model'} and ${customCaptions?.role || 'Role'}.`
          };
        }
        // Map roleId to modelVersionRoleId becuse that's really what it is
        params['modelVersionRoleId'] = params.roleId;
  
        return await getAverageTimes(params);
      },
      getParams: (dataPoint, currentParams = {}) => {
        if (!dataPoint) return currentParams;
        
        const depth = dataPoint.depth || 'V';
        
        if (depth === 'V') {
          return {
            ...currentParams,
            modelVersionId: dataPoint.id || ''
          };
        } else if (depth === 'S') {
          return {
            ...currentParams,
            modelVersionStageId: dataPoint.id || ''
          };
        } else if (depth === 'Z') {
          return {
            ...currentParams,
            modelVersionZoneId: dataPoint.id || ''
          };
        }
        
        return currentParams;
      },
      transformData: (response, filterValues) => {
        if (!response?.data?.data) return null;
    
        return response.data.data.map(item => ({
          id: item.id,
          label: item.name,
          estimatedMinutes: parseFloat((item.estimatedMinutes).toFixed(2)),
          actualMinutes: parseFloat((item.actualMinutes).toFixed(2)),
          depth: response.data.depth
        }));
      },
      drilldown: {
        defaultChartType: 'horizontal-bar',
        allowedChartTypes: ['horizontal-bar', 'vertical-bar'],
        currentDrilldownLabel: () => `${customCaptions.tasksPlural || 'Tasks'}`,
        fetchData: async (params) => {
          if (!params.modelId || !params.roleId) {
            return { 
              status: false, 
              invalidFilters: true,
              message: `Please select at least a ${customCaptions?.model || 'Model'} and ${customCaptions?.role || 'Role'}.`
            };
          }
          // Map roleId to modelVersionRoleId becuse that's really what it is
          params['modelVersionRoleId'] = params.roleId;
    
          return await getAverageTimes(params);
        },
        transformData: (response, filterValues) => {
          if (!response?.data?.data) return null;
      
          return response.data.data.map(item => ({
            id: item.id,
            label: item.name,
            estimatedMinutes: parseFloat((item.estimatedMinutes).toFixed(2)),
            actualMinutes: parseFloat((item.actualMinutes).toFixed(2)),
            depth: response.data.depth
          }));
        },
        getParams: (dataPoint, currentParams = {}) => {
          if (!dataPoint) return currentParams;
          
          const depth = dataPoint.depth || 'V';
          
          if (depth === 'V') {
            return {
              ...currentParams,
              modelVersionId: dataPoint.id || ''
            };
          } else if (depth === 'S') {
            return {
              ...currentParams,
              modelVersionStageId: dataPoint.id || ''
            };
          } 
          // else if (depth === 'Z') {
          //   return {
          //     ...currentParams,
          //     modelVersionZoneId: dataPoint.id || ''
          //   };
          // }
          
          return currentParams;
        },
      }
    }
  };

  return (
    <ReportPage 
      title={`${customCaptions?.service} Average Times`}
      chartConfig={chartConfig}
    />
  );
};

export default ServiceAverageTimesReport; 
