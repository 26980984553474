import instance from "helpers/api";
import { commonSlice } from "./reducers";
import { authSlice } from "../auth/reducers";
// import { authSlice } from "redux/auth/reducers.js";
import { setStorage } from "helpers/storage";
import roles from "helpers/roles";

const {
	setError,
	removeError,
	setLoading,
	openHistoryDrawer,
	setShowData,
	setSuccess,
	removeSuccess,
} = commonSlice.actions;
const { dataSuccess } = authSlice.actions;

export const showError = (message) => (dispatch) =>
	dispatch(setError({ message }));
export const showSuccess = (message) => (dispatch) =>
	dispatch(setSuccess({ message }));

export const loginWithSiteAppId = (id) => async (dispatch) => {
	dispatch(setLoading({ loading: true }));

	try {
		// Store the current URL before switching
		const currentPath = window.location.pathname + window.location.search;

		const res = await instance.get(`/api/Users/LoginToSiteApp/${id}`);
		const data = {
			...res.data,
			isAdmin: localStorage.getItem("isAdmin") === "true",
			isSiteUser: true,
      weekStartDay: res.data.application.weekStartDay,
		};
		const response = await setStorage(data);
		if (response.role === roles.clientAdmin) {
			localStorage.removeItem("clientAdminMode");
		}
		localStorage.removeItem("siteAppId");
		localStorage.removeItem("isAdmin");
		dispatch(dataSuccess({ data }));


		dispatch(setLoading({ loading: false }));

		// Redirect back to the original URL
		window.location.href = currentPath;
		
		return res;
	} catch (error) {
		dispatch(setLoading({ loading: false }));
		throw error;
	}
};

export const hideError = () => (dispatch) => dispatch(removeError());
export const hideSuccess = () => (dispatch) => dispatch(removeSuccess());

export const setHistoryDrawerState = (payload) => (dispatch) =>
	dispatch(openHistoryDrawer({ historyDrawerState: payload }));

//showData for analytics page
export const setAnalyticsShowData = (payload) => (dispatch) => {
	dispatch(
		setShowData({ showData: payload.data, showDataState: payload.state })
	);
};
