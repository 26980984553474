import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const FilterContext = createContext();

export const useFilters = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return context;
};

export const FilterProvider = ({ config, onFiltersChange, children }) => {
  const [filterValues, setFilterValues] = useState({});
  const [isReady, setIsReady] = useState(false);

  const updateFilter = (filterType, value, params) => {
    setFilterValues(prev => ({
      ...prev,
      [filterType]: {
        value: value,
        params: params
      }
    }));
    
    // Create a copy of current filters with the new value for the callback
    const updatedFilters = {
      ...filterValues,
      [filterType]: {
        value: value,
        params: params
      }
    };
    
    onFiltersChange?.(updatedFilters);
  };

  // Helper function to check if a filter is required
  const isRequired = useCallback((filterName) => {
    if (!config?.filters) return false;
    
    const filterConfig = config.filters[filterName];
    // If filter config is an object, check required property
    if (typeof filterConfig === 'object') {
      return !!filterConfig.required;
    }
    
    // If filter config is true, it's using defaults (not required)
    return false;
  }, [config]);

  // Helper function to check if a filter should show "Show All" option
  const isShowAll = useCallback((filterName) => {
    if (!config?.filters) return true; // Default to true
    
    const filterConfig = config.filters[filterName];
    // If filter config is an object with showAll property
    if (typeof filterConfig === 'object') {
      // If showAll is explicitly set, use that value, otherwise default to true
      return filterConfig.showAll !== false;
    }
    
    // If filter config is true or undefined, use default (show all)
    return filterConfig !== false;
  }, [config]);

  // Check if all required filters are set
  useEffect(() => {
    if (!config || !config.filters) return;
    
    const requiredFilters = Object.keys(config.filters);
    const currentFilters = Object.keys(filterValues);
    
    // Check if all required filters have values
    const allFiltersSet = requiredFilters.every(filter => currentFilters.includes(filter));
    
    setIsReady(allFiltersSet);
  }, [filterValues, config]);

  // Helper to build URL params from filter values - memoized with useCallback
  const buildUrlParams = useCallback(() => {
    const params = {};
    
    Object.entries(filterValues).forEach(([_, filterData]) => {
      if (filterData.params) {
        Object.entries(filterData.params).forEach(([paramKey, paramValue]) => {
          // Only add params with actual values (not empty strings)
          if (paramValue !== undefined && paramValue !== '') {
            params[paramKey] = paramValue;
          }
        });
      }
    });
    
    return params;
  }, [filterValues]);

  return (
    <FilterContext.Provider value={{ 
      filterValues, 
      updateFilter,
      isReady,
      buildUrlParams,
      isRequired,
      isShowAll,
      config 
    }}>
      {children}
    </FilterContext.Provider>
  );
}; 
