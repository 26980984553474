import React, { useState } from 'react';
import ReportPage from '../ReportChart/ReportPage';
import { Apis } from "services/api";
import { getPausesByReason } from "services/analytics";
import useChartColors from '../ReportChart/hooks/useChartColors';
const ServicePauseReport = () => {
  const { customCaptions } = JSON.parse(sessionStorage.getItem("me")) || JSON.parse(localStorage.getItem("me"));
  const { getAllColors, getColorsByCategory } = useChartColors();

  const chartConfig = {
    id: 'service-pause',
    title: `${customCaptions?.service} ${customCaptions?.pauseReasonPlural}`,
    fetchData: async (params) => {
      return await getPausesByReason(params);
    },
    allowedChartTypes: ['multi-bar-line'],
    defaultChartType: 'multi-bar-line',
    series: [
      { 
        label: customCaptions?.pauseReasonPlural || 'Pause Reasons', 
        key: 'count', 
        colors: getAllColors(), 
        type: 'bar',
        axisLabel: 'Count'
      },
      { 
        label: 'Average Duration', 
        key: 'average', 
        colors: getColorsByCategory('neutral'), 
        type: 'line',
        axisLabel: 'Average Duration (Hours)'
      }
    ],
    transformData: (response) => {
      return response?.data?.data.map(item => ({
        id: item.id,
        name: item.name,
        label: item.name,
        count: parseInt(item.count || 0),
        average: parseFloat(item.average?.toFixed(2) || 0)
      }));
    },
    filters: {
      timeframe: {
        allowCustomRange: true,
        defaultRange: 'last-year',
        allowedRanges: ['last-month', 'last-6-months', 'last-year', 'custom']
      },
      department: true,
      model: true
    },
    drilldown: {
      defaultChartType: 'multi-bar-line',
      allowedChartTypes: ['multi-bar-line'],
      getParams: (dataPoint) => ({
        pauseId: dataPoint.id
      }),
      currentDrilldownLabel: `${customCaptions?.model || 'Model'}`,
      drilldown: {
        defaultChartType: 'multi-bar-line',
        allowedChartTypes: ['multi-bar-line'],
        getParams: (dataPoint) => ({
          modelId: dataPoint.id
        }),
        currentDrilldownLabel: `${customCaptions?.asset || 'Asset'}`,
      }
    }
  };

  return (
    <ReportPage 
      title={`${customCaptions?.service} ${customCaptions?.pauseReasonPlural}`}
      chartConfig={chartConfig}
    />
  );
};

export default ServicePauseReport; 
