import { useCallback, useRef } from 'react';
import { useCurrentPng, useGenerateImage } from 'recharts-to-png';

/**
 * Custom hook for exporting chart as PNG
 * 
 * @param {Object} options - Configuration options
 * @param {string} options.title - Chart title used for the filename
 * @param {number} options.quality - Image quality (0-1)
 * @param {string} options.backgroundColor - Background color for the exported image
 * @param {React.RefObject} options.chartRef - External ref for the chart (optional)
 * @param {React.RefObject} options.containerRef - External ref for the container (optional)
 * @returns {Object} Export functionality and refs
 */
export const useChartExport = ({ 
  title = 'chart',
  quality = 1.0,
  backgroundColor = '#ffffff',
  chartRef: externalChartRef = null,
  containerRef: externalContainerRef = null
}) => {
  // Create internal refs if external refs are not provided
  const internalChartRef = useRef(null);
  const internalContainerRef = useRef(null);
  
  // Use the provided external refs or fall back to internal refs
  const finalChartRef = externalChartRef || internalChartRef;
  const finalContainerRef = externalContainerRef || internalContainerRef;
  
  // Use the recharts-to-png hook for the chart
  const [getPng, { isLoading: isExportingChart }] = useCurrentPng({
    quality,
    backgroundColor,
    ref: finalChartRef
  });
  
  // Use the useGenerateImage hook as a fallback for the entire container
  const [getContainerImage, { isLoading: isExportingContainer }] = useGenerateImage({
    quality,
    type: 'image/png',
    backgroundColor,
    ref: finalContainerRef
  });
  
  // Combined loading state
  const isExporting = isExportingChart || isExportingContainer;
  
  // Function to download chart as PNG
  const handleExport = useCallback(async () => {
    try {
      console.log('Starting chart export...');
      
      // First try to get the PNG using recharts-to-png
      let png = await getPng();
      
      // If that fails, try to capture the entire container
      if (!png) {
        console.log('Chart-specific export failed, trying container export...');
        png = await getContainerImage();
      }
      
      if (png) {
        console.log('PNG generated successfully, preparing download...');
        
        // Create a formatted date for the filename
        const date = new Date();
        const formattedDate = date.toISOString().split('T')[0];
        const formattedTime = date.toTimeString().split(' ')[0].replace(/:/g, '-');
        
        // Create a download link and trigger download
        const downloadLink = document.createElement('a');
        downloadLink.href = png;
        downloadLink.download = `${title || 'chart'}_${formattedDate}_${formattedTime}.png`;
        downloadLink.click();
        
        console.log('Download triggered successfully');
        return true;
      } else {
        console.error('Failed to generate PNG: result was null or undefined');
        return false;
      }
    } catch (error) {
      console.error('Error exporting chart:', error);
      return false;
    }
  }, [getPng, getContainerImage, title]);

  return {
    handleExport,
    chartRef: finalChartRef,
    containerRef: finalContainerRef,
    isExporting
  };
}; 